// helper/ScrollHelper.js
const ScrollHelper = {
  install(app) {
    app.config.globalProperties.$go = function (id) {
      this.$nextTick(() => {
        const targetElement = document.getElementById(id);

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }

      });
    }
  }
};

export default ScrollHelper;
