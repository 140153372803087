<template>


        <!-- Start breadcrumb Area -->
    <div class="rbt-breadcrumb-default ptb--40 ptb_md--50 ptb_sm--30 bg-gradient-1">
        <div style="text-align: center;margin-bottom: 30px;">
            <a href="#/employer"><img src="assets/images/logo/logo.png" :alt="$t('projectName')" style="max-width: 400px;"></a>
        </div>
    </div>
    <!-- End Breadcrumb Area -->

    <div class="rbt-elements-area bg-color-white rbt-section-gap" style="padding-top: 10px;">
        <div class="container">
            <div class="row gy-5 row--30">

                <div class="col-lg-6" style="float:none;margin:auto;">
                    <div class="rbt-contact-form contact-form-style-1 max-width-auto">
                        <h3 class="title" style="margin-bottom: 15px;">{{ $t("logIn") }}</h3>
                        <form class="max-width-auto" @submit.prevent="login">
                            <div class="form-group">
                                <input name="con_name" type="text" v-model="formData.email">
                                <label>{{ $t("companyEmailAddress") }} *</label>
                                <span class="focus-border"></span>
                            </div>
                            <div class="form-group">
                                <input name="con_password" type="password" v-model="formData.password">
                                <label>{{ $t("password") }} *</label>
                                <span class="focus-border"></span>
                            </div>

                            <div class="form-submit-group">
                                <button type="submit" class="rbt-btn btn-md btn-gradient hover-icon-reverse w-100" @click="login">
                                    <span class="icon-reverse-wrapper">
                                        <span class="btn-text">{{ $t("logIn") }}</span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    </span>
                                </button>
                            </div>

                            <div class="row mt--30">
                                <div class="col-12">
                                    <div class="rbt-lost-password text-center">
                                        <a class="rbt-btn-link" href="#/forgot-password">{{ $t("forgotMyPassword") }}</a>
                                    </div>
                                </div>
                            </div>

    
                        </form>
                    </div>

                    <div class="row mt--30 text-center" style="width: 60%;margin-left: 20%;">
                        <span style="font-size:14px;">{{ $t("dontHaveAnAccount") }}</span>
                        <a class="rbt-btn hover-icon-reverse btn-border-gradient radius-round" href="#/register">
                            <div class="icon-reverse-wrapper">
                                <span class="btn-text">{{ $t("createNewAccount") }}</span>
                                <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    </div>


</template>
<script>
import { doc, getDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  // onAuthStateChanged,
  // updateProfile,
  // updateEmail,
  // updatePassword,
  // reauthenticateWithCredential,
  // signOut,  
} from "firebase/auth";
import Swal from 'sweetalert2';

export default {
    components: {
    
    },
    data() {
        return {
            formData: {
                email: '',
                password: '',
            }
        };
    },
    methods:{
        async login() {
            if(this.formData.email == '' || this.formData.password == ''){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseEnterEmailAndPassword'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                const email = this.formData.email;
                const password = this.formData.password;

                var mailPattern = new RegExp("^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$");
                if((!mailPattern.test(email))){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('invalidEmail'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });
                }else{

                    this.$setLoading(true);
                    const auth = getAuth();
                    signInWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                    
                        let user = userCredential.user;

                        const documentRef = doc(getFirestore(), 'users', user.uid);
                        const documentSnap = await getDoc(documentRef);
                        this.$setLoading(false);
                        if(documentSnap.exists()){
                            
                            localStorage.setItem("name", documentSnap.data().name);
                            localStorage.setItem("companyName", documentSnap.data().companyName);
                            let photoUrl = "";
                            if(documentSnap.data().photoUrl != null && documentSnap.data().photoUrl != ""){
                                photoUrl = documentSnap.data().photoUrl;
                            }
                            localStorage.setItem("photoUrl", photoUrl);
                            localStorage.setItem("type", documentSnap.data().role);

                            if(documentSnap.data().role=="employer"){
                                this.$router.push('/employer-dashboard');
                            }else if(documentSnap.data().role=="admin"){
                                this.$router.push('/admin-dashboard');
                            }
                        }else{
                            Swal.fire({
                                title: this.$t('error'),
                                text: this.$t('userInformationIncorrect'),
                                icon: 'error',
                                confirmButtonText: this.$t('ok')
                            });
                        }
                    })
                    .catch((error) =>{
                        this.$setLoading(false);
                        console.log("error",error);
                        Swal.fire({
                            title: this.$t('error'),
                            text: this.$t('userInformationIncorrect'),
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                }

            }
        }
    }
}
</script>