<template>
    <div style="text-align: center;margin-top: 30px;">
        <img src="assets/images/logo/logo.png" :alt="$t('projectName')">
    </div>

    <!-- Start Banner Area -->
    <div class="rbt-banner-area rbt-banner-8 variation-02 with-shape">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-10 offset-lg-1">
                    <div class="content">
                        <div class="inner text-center">
                            <h1 class="title">
                                <span class="header-caption">
                                    <span class="cd-headline clip is-full-width">
                                        <span class="cd-words-wrapper">
                                            <b class="is-visible theme-gradient">{{ $t('postingJob') }}</b>
                                            <b class="is-hidden theme-gradient">{{ $t('lookingJob') }}</b>
                                            <!-- <b class="is-hidden theme-gradient">{{ $t('postingTvSystem') }}</b> -->
                                        </span>
                                </span>
                                </span>
                            </h1>
                            <div class="slider-btn rbt-button-group justify-content-center" style="margin-top: 50px;">
                                <a class="rbt-btn btn-gradient hover-icon-reverse" href="#/employer">
                                    <span class="icon-reverse-wrapper">
                                        <span class="btn-text">{{ $t('postingJob') }}</span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    </span>
                                </a>
                                <a class="rbt-btn hover-icon-reverse btn-white" href="#/worker">
                                    <span class="icon-reverse-wrapper">
                                        <span class="btn-text">{{ $t('lookingJob') }}</span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shape-image">
                <img src="assets\images\icons\bg.svg">
            </div>
        </div>
    </div>
    <!-- End Banner Area -->
</template>
<script>

export default {
    components: {
    
    },
    data() {
        return {
            
        };
    },
    async mounted() {

    },  
    methods:{

    }
}
</script>