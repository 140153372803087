<template>
<div>
    Second Page
    <div>
    <button @click="$go('target-element')">Scroll to Target</button>
    
    <div style="height: 1500px;">
      <!-- Boş alan, kaydırma işlemini gözlemlemek için -->
    </div>

    <div id="target-element" style="height: 200px; background-color: lightblue;">
      Hedef Element
    </div>
  </div>
</div>
</template>
<script>


export default {
    components: {
    
    },
    data() {
        return {
            
        };
    },
    async mounted() {

    },  
    methods:{

    }
}
</script>