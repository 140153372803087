<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <AdminMenu :activeMenu="'posts'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">Job Postings</h4>
                                    </div>
                                        <div class="row g-5">

                                        <div class="rbt-dashboard-table table-responsive mobile-table-750 mt--30">
                                            <table class="rbt-table table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th>Job Name</th>
                                                        <th>Created Date</th>
                                                        <th>Payment Status</th>
                                                        <th>Status</th>
                                                        <th>Redirect Count</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in items" :key="item[id]">
                                                        <th>
                                                            <p class="b3">{{ item.jobName }}</p>
                                                        </th>
                                                        <td>
                                                            <p class="b3">{{ item.createdDateStr }}</p>
                                                        </td>
                                                        <td>
                                                            <p class="b3" v-if="item['paymentStatus']=='success'">
                                                                <span class="rbt-badge-5 bg-color-info-opacity color-success">{{ $t("success") }}</span>
                                                            </p>
                                                            <p class="b3" v-if="item['paymentStatus']=='pending'">
                                                                <span class="rbt-badge-5 bg-color-info-opacity color-secondary">{{ $t("pending") }}</span>
                                                            </p>
                                                            <p class="b3" v-if="item['paymentStatus']=='failed'">
                                                                <span class="rbt-badge-5 bg-color-danger-opacity color-danger">{{ $t("failed") }}</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p class="b3" v-if="item['status']=='active'">
                                                                <span class="rbt-badge-5 bg-color-info-opacity color-success">{{ $t("active") }}</span>
                                                            </p>
                                                            <p class="b3" v-if="item['status']=='pending'">
                                                                <span class="rbt-badge-5 bg-color-info-opacity color-secondary">{{ $t("pending") }}</span>
                                                            </p>
                                                            <p class="b3" v-if="item['status']=='canceled'">
                                                                <span class="rbt-badge-5 bg-color-danger-opacity color-danger">{{ $t("canceled") }}</span>
                                                            </p>
                                                        </td>
                                                        <td><i class="fa fa-forward"></i> {{ item.redirectCount }}</td>
                                                        <td>
                                                            <div class="rbt-button-group justify-content-end">
                                                                <a class="rbt-btn btn-xs bg-color-primary-opacity radius-round color-primary" href="javascript:;" title="Detail Job"  v-if="item['status']=='active'" @click="detailPost(item.id)"><i class="feather-search"></i> Detail Job</a>
                                                                <a class="rbt-btn btn-xs bg-color-success-opacity radius-round color-success" href="javascript:;" title="Detail Job"  v-if="item['status']=='pending'" @click="confirmPost(item.id)"><i class="feather-check"></i> Confirm Job</a>
                                                                <a class="rbt-btn btn-xs bg-color-danger-opacity radius-round color-danger" href="javascript:;" title="Cancel Job"  v-if="item['status']=='active' || item['status']=='pending'" @click="cancelPost(item.id)"><i class="feather-trash-2 pl--0"></i> Cancel Job</a>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->


    <div v-if="isOpenModal" class="modal-overlay">
        <div class="modal-container">
            <button class="modal-close" @click="closeModal">×</button>
            <div class="modal-content" style="width: 800px;">
                <div class="col-12" >
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Company Name:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.companyName }} <img :src="modalContent.companyPhotoUrl" style="width: 50px;" /></div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Location:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.locationName }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Job Type:</div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="rbt-profile-content b3">{{ modalContent.adType }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Job Name:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.jobName }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Start Date - End Date:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ $formatDate(modalContent.startDate) }} - {{ $formatDate(modalContent.endDate) }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Total Amount:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">$ {{ modalContent.totalAmount }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Status:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.status }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Payment Status:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.paymentStatus }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Crated Date:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.createdDateStr }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Redirect Url:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3"><a :href="modalContent.redirectUrl" target="_blank">Link</a></div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Redirect Count:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.redirectCount }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='image'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Image:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3"><a :href="modalContent.imageUrl" target="_blank"><img :src="modalContent.imageUrl" style="width: 200px;" /></a></div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='design'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Working Type:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.workingType }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='design'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Salary Period:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.salaryPeriod }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='design'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Salary Paid:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.salaryPaid }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='design'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Responsibilities:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.responsibilities }}</div>
                        </div>
                    </div>
                    <div class="rbt-profile-row row row--15" v-if="modalContent.adType=='design'">
                        <div class="col-lg-4 col-md-3">
                            <div class="rbt-profile-content b3 bold text-end">Benefits:</div>
                        </div>
                        <div class="col-lg-8 col-md-9">
                            <div class="rbt-profile-content b3">{{ modalContent.benefits }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AdminMenu from '../components/AdminMenu';
import { getDocs, query, collection, doc, updateDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';

export default {
    components: {
        AdminMenu
    },
    data() {
        return {
            items: [],
            isOpenModal: false,
            modalContent:{
                companyName: "",
                companyPhotoUrl: "",
                jobName: "",
                adType: "", //image/desing
                locationName: "",
                startDate: "",
                endDate: "",
                weeks: "",
                totalAmount: 0,
                status: "",
                paymentStatus: "",
                createdDate: "",
                createdDateStr: "",
                redirectUrl: "",
                redirectCount: "",
                imageUrl: "",
                workingType: "",
                salaryPeriod: "",
                salaryPaid: "",
                responsibilities: "",
                benefits: ""
            }
        };
    },
    async mounted() {
        this.getPosts();
    },  
    methods:{
        async getPosts() {
            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'jobs')));
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    createdDateStr: this.$formatDate(doc.data().createdDate),
                    ...doc.data()
                };
            });
            
        },
        async detailPost(id){
            this.modalContent = this.items.find(item => item.id == id);
            this.isOpenModal = true;
        },
        async confirmPost(id){
            let job = this.items.find(item => item.id == id);
            
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureActive'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        this.$setLoading(true);
                        job.status = 'active';
                        const documentRef = doc(getFirestore(), 'jobs', id);
                        await updateDoc(documentRef, { ...job });
                        this.$setLoading(false);

                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('activeSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        await this.getPosts();
                    } catch (error) {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
        async cancelPost(id){
            let job = this.items.find(item => item.id == id);
            
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureCancel'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('cancel'),
                cancelButtonText: this.$t('close')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        this.$setLoading(true);
                        job.status = 'canceled';
                        const documentRef = doc(getFirestore(), 'jobs', id);
                        await updateDoc(documentRef, { ...job });
                        this.$setLoading(false);
                        
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('cancelSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        await this.getPosts();
                    } catch (error) {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
        closeModal(){
            this.isOpenModal = false;
        },
    }
}
</script>