import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
import { doc, getDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";

import Second from '../views/Second'

import PagePrivacyPolicy from '../views/PagePrivacyPolicy'
import PageServicePolicy from '../views/PageServicePolicy'

import Layout from '../views/Layout'
import Default from '../views/Default'

import View from '../views/View'
import Live from '../views/Live'

import LayoutWorker from '../views/LayoutWorker'
import Worker from '../views/Worker'
import WorkerJobs from '../views/WorkerJobs'

import LayoutEmployer from '../views/LayoutEmployer'
import Employer from '../views/Employer'
import EmployerDashboard from '../views/EmployerDashboard'
import EmployerCreatePost from '../views/EmployerCreatePost'
import EmployerPosts from '../views/EmployerPosts'
import EmployerProfile from '../views/EmployerProfile'

import AdminDashboard from '../views/AdminDashboard'
import AdminLocations from '../views/AdminLocations'
import AdminLocationAdd from '../views/AdminLocationAdd'
import AdminCompanies from '../views/AdminCompanies'
import AdminCompanyAdd from '../views/AdminCompanyAdd'
import AdminPosts from '../views/AdminPosts'
import AdminLive from '../views/AdminLive'
import AdminProfile from '../views/AdminProfile'

import Login from '../views/Login'
import Register from '../views/Register'
import ForgotPassword from '../views/ForgotPassword'


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/layout',
      component: Layout,
      children: [
        {
          name: 'default',
          path: '/',
          component: Default,         
        },
        {
          name: 'second',
          path: '/second',
          component: Second,         
        },
        {
          name: 'view',
          path: '/view/:id',
          component: View,         
        },
        {
          name: 'live',
          path: '/live/:id',
          component: Live,         
        },
        {
          name: 'privacy-policy',
          path: '/privacy-policy',
          component: PagePrivacyPolicy,         
        },
        {
          name: 'terms-of-service',
          path: '/terms-of-service',
          component: PageServicePolicy,         
        },
        {
          name: 'layout-worker',
          path: '/layout-worker',
          component: LayoutWorker,
          children: [
            {
              name: 'worker',
              path: '/worker',
              component: Worker,         
            },
            {
              name: 'jobs',
              path: '/jobs',
              component: WorkerJobs,         
            },
          ],         
        },
        {
          name: 'layout-employer',
          path: '/layout-employer',
          component: LayoutEmployer,
          children: [
            {
              name: 'employer',
              path: '/employer',
              component: Employer,         
            },
            {
              name: 'employer-dashboard',
              path: '/employer-dashboard',
              component: EmployerDashboard,         
            },
            {
              name: 'employer-create-post',
              path: '/employer-create-post',
              component: EmployerCreatePost,         
            },
            {
              name: 'employer-posts',
              path: '/employer-posts',
              component: EmployerPosts,         
            },
            {
              name: 'employer-profile',
              path: '/employer-profile',
              component: EmployerProfile,         
            },
          ],
        },
        {
          name: 'login',
          path: '/login',
          component: Login,         
        },
        {
          name: 'register',
          path: '/register',
          component: Register,         
        },
        {
          name: 'forgot-password',
          path: '/forgot-password',
          component: ForgotPassword,         
        },
        {
          name: 'admin-dashboard',
          path: '/admin-dashboard',
          component: AdminDashboard,         
        },
        {
          name: 'admin-posts',
          path: '/admin-posts',
          component: AdminPosts,         
        },
        {
          name: 'admin-live',
          path: '/admin-live',
          component: AdminLive,         
        },
        {
          name: 'admin-profile',
          path: '/admin-profile',
          component: AdminProfile,
        },
        {
          name: 'admin-locations',
          path: '/admin-locations',
          component: AdminLocations,
        },
        {
          name: 'admin-location-add',
          path: '/admin-location-add/:id',
          component: AdminLocationAdd,
        },
        {
          name: 'admin-companies',
          path: '/admin-companies',
          component: AdminCompanies,         
        },
        {
          name: 'admin-company-add',
          path: '/admin-company-add/:id',
          component: AdminCompanyAdd,
        },
      ],
    }
  ],

});

const scrollPages = ['/employer','/worker']
const adminPages = ['/admin-dashboard', '/admin-posts', '/admin-profile', '/admin-locations', '/admin-location-add', '/admin-companies'];
const employerPages = ['/employer-dashboard', '/employer-create-post', '/employer-posts', '/employer-profile'];
const loginPages = ['/login', '/register', '/forgot-password'];

router.beforeEach((to, from, next)=>{
  if (!scrollPages.includes(to.path)){
    console.log(to.path);
    window.scrollTo(0, 0);
  }

  const auth = getAuth();
  if (employerPages.includes(to.path)){
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        const documentRef = doc(getFirestore(), 'users', user.uid);
        const documentSnap = await getDoc(documentRef);
        
        if(documentSnap.exists()){
            if(documentSnap.data().role=="employer"){
                next(true);
            }else if(documentSnap.data().role=="admin"){
                next('/login');
            }
        }else{
          next('/login');
        }

      }else{
        next("/login");
      }
    });
  }else if(adminPages.includes(to.path)){
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        const documentRef = doc(getFirestore(), 'users', user.uid);
        const documentSnap = await getDoc(documentRef);
        
        if(documentSnap.exists()){
            if(documentSnap.data().role=="employer"){
                next('/login');
            }else if(documentSnap.data().role=="admin"){
                next(true);
            }
        }else{
          next('/login');
        }
        
      }else{
        next("/login");
      }
    });
  }else if(loginPages.includes(to.path)){
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const documentRef = doc(getFirestore(), 'users', user.uid);
        const documentSnap = await getDoc(documentRef);
        
        if(documentSnap.exists()){
            if(documentSnap.data().role=="employer"){
                next('/employer-dashboard');
            }else if(documentSnap.data().role=="admin"){
                next('/admin-dashboard');
            }
        }else{
          next(true);
        }
      }else{
        next(true);
      }
    });
  }else if(to.path == '/logout'){
    getAuth()
    .signOut()
    .then(() => {
        next('/employer');
    })
    .catch(error => {
        console.log(error.message);
    });
  }else{
    next(true);
  }

});

export default router