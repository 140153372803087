import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB3kMJIealMfMISr_nK0d7HNwOQUKWvSMY",
    authDomain: "peakcareer-66.firebaseapp.com",
    projectId: "peakcareer-66",
    storageBucket: "peakcareer-66.appspot.com",
    messagingSenderId: "131588709200",
    appId: "1:131588709200:web:f8b0ab3dec55cb1c022a55",
    measurementId: "G-7FWCTT7155"   
}

// init firebase
initializeApp(firebaseConfig)

// init firestore service
const db = getFirestore()
export default db