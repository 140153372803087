<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <AdminMenu :activeMenu="'dashboard'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">{{ $t("dashboard") }}</h4>
                                    </div>
                                    <div class="row g-5">

                                        <!-- Start Single Card  -->
                                        <a href="#/admin-posts" class="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed bg-primary-opacity">
                                                <div class="inner">
                                                    <div class="rbt-round-icon bg-primary-opacity">
                                                        <i class="feather-award"></i>
                                                    </div>
                                                    <div class="content">
                                                        <span class="rbt-title-style-3 d-block">{{ $t("pendingPosts") }} ({{ pendingJobCount  }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <a href="#/admin-posts" class="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed bg-violet-opacity">
                                                <div class="inner">
                                                    <div class="rbt-round-icon bg-violet-opacity">
                                                        <i class="feather-list"></i>
                                                    </div>
                                                    <div class="content">
                                                        <span class="rbt-title-style-3 d-block">{{ $t("jobPostings") }} ({{ jobCount  }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <!-- End Single Card  -->

                                        <!-- Start Single Card  -->
                                        <a href="#/admin-companies" class="col-lg-4 col-md-4 col-sm-6 col-12">
                                            <div class="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed bg-pink-opacity">
                                                <div class="inner">
                                                    <div class="rbt-round-icon bg-pink-opacity">
                                                        <i class="feather-users"></i>
                                                    </div>
                                                    <div class="content">
                                                        <span class="rbt-title-style-3 d-block">{{ $t("companies") }} ({{ companyCount  }})</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        <!-- End Single Card  -->

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import AdminMenu from '../components/AdminMenu';
import { getDocs, query, where, collection } from "firebase/firestore";
import db from '../firebase/init';
import {
  getAuth,
  onAuthStateChanged
} from "firebase/auth";
export default {
    components: {
        AdminMenu
    },
    data() {
        return {
            jobCount: 0,
            pendingJobCount: 0,
            companyCount: 0
        };
    },
    async mounted() {
        this.$setLoading(true);
        await this.getPostsCounts();
        this.$setLoading(false);
    },  
    methods:{
        async getPostsCounts() {
            const querySnap = await getDocs(query(collection(db, 'jobs')));
            this.jobCount = querySnap.size;

            const querySnap2 = await getDocs(query(collection(db, 'jobs'),where('status','==','pending')));
            this.pendingJobCount = querySnap2.size;
            
            const querySnap3 = await getDocs(query(collection(db, 'users')));
            this.companyCount = querySnap3.size;
            
        },
    }
}
</script>