<template>
    <!-- Start Header Area -->
    <header class="rbt-header">

        <div class="rbt-sticky-placeholder"></div>

            <div class="rbt-header-wrapper">
                <div class="container">
                    <div class="mainbar-row rbt-navigation-center align-items-center">
                        <div class="header-left">
                            <div class="logo">
                                <a href="#/">
                                    <img src="assets\images\logo\logo.png" style="width:250px;">
                                </a>
                            </div>
                        </div>

                        <div class="rbt-main-navigation d-none d-xl-block">
                            <nav class="mainmenu-nav onepagenav">
                                <ul class="mainmenu">
                                    <li class="current">
                                        <a href="#/employer" @click="$go('employerHome');">{{ $t("home") }}</a>
                                    </li>
                                    <li>
                                        <a href="#/employer" @click="$go('howItWorks');">{{ $t("howItWorks") }}</a>
                                    </li>
                                    <li>
                                        <a href="#/employer" @click="$go('pricing');">{{ $t("pricing") }}</a>
                                    </li>
                                    <li>
                                        <a href="#/employer" @click="$go('faq');">{{ $t("frequentlyAskedQuestions") }}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="header-right">

                            <!-- Navbar Icons -->
                            <ul class="quick-access">
                                <li class="account-access rbt-user-wrapper d-none d-xl-block" v-if="!isLogin">
                                    <a href="#/login"><i class="feather-lock"></i>{{ $t("loginAndRegister") }}</a>
                                </li>
                                <li class="account-access rbt-user-wrapper d-none d-xl-block" v-if="isLogin">
                                    <a href="#/employer-dashboard"><i class="feather-user"></i> {{ name }}</a>
                                    <div class="rbt-user-menu-list-wrapper">
                                        <div class="inner">
                                            <div class="rbt-admin-profile">
                                                <div class="admin-thumbnail">
                                                    <img :src="photoUrl == '' ? '/assets/images/no_user_image.png' : photoUrl">
                                                </div>
                                                <div class="admin-info">
                                                    <span class="name">{{ name }} ({{ companyName }})</span>
                                                    <a class="rbt-btn-link color-primary" :href="isEmployer ? '#/employer-profile' : '#/admin-profile'">{{ $t("myProfile") }}</a>
                                                </div>
                                            </div>
                                            <ul class="user-list-wrapper" v-if="isEmployer">
                                                <li>
                                                    <a href="#/employer-dashboard">
                                                        <i class="feather-home"></i>
                                                        <span>{{ $t("dashboard") }}</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#/employer-create-post">
                                                        <i class="feather-plus"></i>
                                                        <span>{{ $t("createJobPosting") }}</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#/employer-posts">
                                                        <i class="feather-list"></i>
                                                        <span>{{ $t("myJobPostings") }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul class="user-list-wrapper" v-if="!isEmployer">
                                                <li>
                                                    <a href="#/admin-dashboard">
                                                        <i class="feather-home"></i>
                                                        <span>{{ $t("dashboard") }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            <hr class="mt--10 mb--10">
                                            <ul class="user-list-wrapper">
                                                <li>
                                                    <a href="#/logout">
                                                        <i class="feather-log-out"></i>
                                                        <span>{{ $t("logout") }}</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <!-- <ul class="quick-access">
                                <li class="account-access rbt-user-wrapper d-none d-xl-block">
                                    <a href="#"><i class="feather-lock"></i>Login & Register</a>
                                </li>
                            </ul> -->


                        </div>

                    </div>
                </div>

            </div>
        </header>

    <a class="close_side_menu" href="javascript:void(0);"></a>


    <router-view></router-view>

    <div class="rbt-separator-mid" style="padding: 20px;">
        <div class="container">
            <hr class="rbt-separator m-0">
        </div>
    </div>
    <!-- Start Footer aera -->
    <footer class="rbt-footer footer-style-1">
        <div class="footer-top">
            <div class="container">
                <div class="row row--15 mt_dec--30">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12 mt--30">
                        <div class="footer-widget">
                            <div class="logo">
                                <img src="assets\images\logo\logo.png" style="width: 200px;">
                            </div>

                            <p class="description mt--20">{{ $t("catchword") }} 
                            </p>

                        </div>
                    </div>

                    <div class="offset-lg-1 col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                        <div class="footer-widget">
                            <h5 class="ft-title">Useful Links</h5>
                            <ul class="ft-link">
                                <li>
                                    <a href="#/employer">{{ $t("postingJob") }}</a>
                                </li>
                                <li>
                                    <a href="#/worker">{{ $t("lookingJob") }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6 col-12 mt--30">
                        <div class="footer-widget">
                            <h5 class="ft-title">Job Postings</h5>
                            <ul class="ft-link">
                                <li>
                                    <a href="#/employer">Login & Register</a>
                                </li>
                                <li>
                                    <a href="#/employer">How It Works</a>
                                </li>
                                <li>
                                    <a href="#/employer">Pricing</a>
                                </li>
                                <li>
                                    <a href="#/employer">Rent Location</a>
                                </li>
                                <li>
                                    <a href="#/employer">FAQ</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 mt--30">
                        <div class="footer-widget">
                            <h5 class="ft-title">Get Contact</h5>
                            <ul class="ft-link">
                                <li><span>Phone:</span> <a href="#">(432) 250-9232</a></li>
                                <li><span>E-mail:</span> <a href="mailto:hr@example.com">peakcareer1@gmail.com</a></li>
                                <li><span>Location:</span> Houston Florida, USA</li>
                            </ul>
                            <ul class="social-icon social-default icon-naked justify-content-start mt--20" style="font-size: 14px;">
                                <li><a href="https://www.facebook.com/" target="_blank">
                                        <i class="feather-facebook"></i>
                                    </a>
                                </li>
                                <li><a href="https://www.twitter.com" target="_blank">
                                        <i class="feather-twitter"></i>
                                    </a>
                                </li>
                                <li><a href="https://www.instagram.com/" target="_blank">
                                        <i class="feather-instagram"></i>
                                    </a>
                                </li>
                                <li><a href="https://www.linkdin.com/" target="_blank">
                                        <i class="feather-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- End Footer aera -->
    <div class="rbt-separator-mid">
        <div class="container">
            <hr class="rbt-separator m-0">
        </div>
    </div>

    <!-- Start Copyright Area  -->
    <div class="copyright-area copyright-style-1 ptb--20">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
                    <p class="rbt-link-hover text-center text-lg-start">Copyright © 2024 <b>Peak Career</b>. All Rights Reserved.</p>
                </div>
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
                    <ul class="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                        <li><a href="#/terms-of-service">Terms of service</a></li>
                        <li><a href="#/privacy-policy">Privacy policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copyright Area  -->
</template>
<script>

import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
/*import { query, collection, getDocs, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';*/
export default {
    components: {
        
    },
    data() {
        return {
            isLogin: false,
            isEmployer: true,
            name: "",
            companyName: "",
            photoUrl: ""
        }
    },
    mounted(){


    },
    created() {
        this.loginControl();
    },
    methods: {
        loginControl(){
            this.$setLoading(true);
            getAuth().onAuthStateChanged(user => {
                this.$setLoading(false);
                if (user) {
                    //this.adminInfo = user.email
                    this.isLogin = true;
                    this.name = localStorage.getItem("name");
                    this.companyName = localStorage.getItem("companyName");
                    this.photoUrl = localStorage.getItem("photoUrl");
                    const type = localStorage.getItem("type");
                    if(type=="employer"){
                        this.isEmployer = true;
                    }else{
                        this.isEmployer = false;
                    }
                    
                }else{
                    this.isLogin = false;
                }
            });
        }
    }
}
</script>