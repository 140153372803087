<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <EmployerMenu :activeMenu="'create-post'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content" style="font-size: 14px;">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">Create Job Posting</h4>
                                    </div>
                                    <div class="row g-5" v-if="visibleScreen.choise">
                                        <div class="col-md-6">
                                            <div class="call-to-btn text-center position-relative">
                                                <a class="rbt-btn btn-sm rbt-switch-btn rbt-switch-y" href="javascript:;" @click="goToScreen('upload')">
                                                    <i class="feather-image"></i> <span data-text="I have an ad image">I have an ad image</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="call-to-btn text-center position-relative">
                                                <a class="rbt-btn btn-sm rbt-switch-btn rbt-switch-y" href="javascript:;" @click="goToScreen('design')">
                                                    <i class="feather-edit-3"></i> <span data-text="I will design an ad from strach">I will design an ad from strach</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-5" v-if="visibleScreen.upload">
                                        <div class="col-6 text-start">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('choise')"><i class="feather-arrow-left"></i> Go Back</a>
                                        </div>
                                        <div class="col-6 text-end">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('payment')">Continue <i class="feather-arrow-right"></i></a>
                                        </div>
                                        <div class="col-4">
                                            <h5>Location</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <select class="w-100" style="height: 50px;" v-model="formData.locationId" @change="setLocation" >
                                                    <option v-for="location in locationItems" :value="location['id']" :key="location['id']">{{ location['name'] }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <h5>Start Date</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <Datepicker v-model="formData.startDate" style="height: 50px;"/>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <h5>End Date</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <Datepicker v-model="formData.endDate" style="height: 50px;"/>
                                            </div>
                                        </div>
                                        <div class="col-12" style="font-size: 14px;min-height: 500px;">
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="col-12 mb-3">
                                                        <div class="rbt-form-group">
                                                            <label for="jobName">Job Name <span class="require">(*)</span></label>
                                                            <input id="jobName" type="text" v-model="formData.jobName" maxlength="100" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <div class="rbt-form-group">
                                                            <label for="redirectUrl">Redirect Url <span style="font-weight: normal;">(for QR Code)</span> <span class="require">(*)</span></label>
                                                            <input id="redirectUrl" type="text" v-model="formData.redirectUrl" placeholder="https://" required />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mb-3">
                                                        <div class="rbt-form-group">
                                                            <label for="formFile">Upload a Job Posting Image (460x280) <span class="require">(*)</span></label>
                                                            <input class="form-control" type="file" id="formFile" @change="handleFileChange">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                            <b>Preview:</b><br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="rbt-card variation-01 rbt-hover">
                                                        <div class="rbt-card-body text-center">
                                                            <div class="row">
                                                                <div class="col-9">
                                                                    <a href="javascript:;" class="rbt-badge-2">
                                                                        <div class="image">
                                                                            <img :src="formData.companyPhotoUrl == '' ? '/assets/images/no_user_image.png' : formData.companyPhotoUrl">
                                                                        </div> Job advertisement from <strong>{{ formData.companyName }}</strong>
                                                                    </a>
                                                                </div>
                                                                <div class="col-3" style="margin-top: -40px;">
                                                                    <a class="rbt-btn-link" href="javascript:;">for detail<i class="feather-arrow-down"></i><br><span style="font-size: 80px"><i class="fa fa-qrcode"></i></span></a>
                                                                </div>
                                                            </div>
                                                            <h4 class="rbt-card-title rbt-badge-6 bg-primary-opacity mt-5" style="text-transform: none;line-height: 30px;">{{ formData.jobName }}</h4>
                                                            
                                                            <img :src="formData.imageUrl" style="width: 100%;height: 280px;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row g-5" v-if="visibleScreen.design">
                                        <div class="col-6 text-start">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('choise')"><i class="feather-arrow-left"></i> Go Back</a>
                                        </div>
                                        <div class="col-6 text-end">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('payment')">Continue <i class="feather-arrow-right"></i></a>
                                        </div>
                                        <div class="col-4">
                                            <h5>Location</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <select class="w-100" style="height: 50px;" v-model="formData.locationId" @change="setLocation" >
                                                    <option v-for="location in locationItems" :value="location['id']" :key="location['id']">{{ location['name'] }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <h5>Start Date</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <Datepicker v-model="formData.startDate" style="height: 50px;"/>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <h5>End Date</h5>
                                            <div class="rbt-modern-select bg-transparent h5">
                                                <Datepicker v-model="formData.endDate" style="height: 50px;"/>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="row">
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="jobName">Job Name <span class="require">(*)</span></label>
                                                        <input id="jobName" type="text" v-model="formData.jobName" maxlength="100" required />
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="redirectUrl">Redirect Url <span style="font-weight: normal;">(for QR Code)</span> <span class="require">(*)</span></label>
                                                        <input id="redirectUrl" type="text" v-model="formData.redirectUrl" placeholder="https://" required />
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="workingType">Working Type <span class="require">(*)</span></label>
                                                        <select id="workingType" style="height: 50px;" v-model="formData.workingType">
                                                            <option class="Full-Time">Full-Time</option>
                                                            <option class="Part-Time">Part-Time</option>
                                                            <option class="Flexible Work">Flexible Work</option>
                                                            <option class="Remote Work">Remote Work</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="salaryPeriod">Salary Paid <span class="require">(*)</span></label>
                                                        <select id="salaryPeriod" style="height: 50px;" v-model="formData.salaryPeriod">
                                                            <option class="Annual">Annual</option>
                                                            <option class="Monthly">Monthly</option>
                                                            <option class="Bi-Weekly">Bi-Weekly</option>
                                                            <option class="Weekly">Weekly</option>
                                                            <option class="Daily">Daily</option>
                                                            <option class="Hourly">Hourly</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="salaryPaid">Salary Paid ($) <span class="require">(*)</span></label>
                                                        <input id="salaryPaid" type="text" v-model="formData.salaryPaid" required />
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="responsibilities">Responsibilities <span class="require">(*)</span></label>
                                                        <textarea v-model="formData.responsibilities" maxlength="100" required></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-3">
                                                    <div class="rbt-form-group">
                                                        <label for="benefits">Benefits <span class="require">(*)</span></label>
                                                        <textarea v-model="formData.benefits" maxlength="100" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <b>Preview:</b><br>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="rbt-card variation-01 rbt-hover">
                                                        <div class="rbt-card-body text-center">
                                                            <div class="row">
                                                                <div class="col-9">
                                                                    <a href="javascript:;" class="rbt-badge-2">
                                                                        <div class="image">
                                                                            <img :src="formData.companyPhotoUrl == '' ? '/assets/images/no_user_image.png' : formData.companyPhotoUrl">
                                                                        </div> Job advertisement from <strong>{{ formData.companyName }}</strong>
                                                                    </a>
                                                                </div>
                                                                <div class="col-3" style="margin-top: -40px;">
                                                                    <a class="rbt-btn-link" href="javascript:;">for detail<i class="feather-arrow-down"></i><br><span style="font-size: 80px"><i class="fa fa-qrcode"></i></span></a>
                                                                </div>
                                                            </div>
                                                            <h4 class="rbt-card-title rbt-badge-6 bg-primary-opacity mt-5" style="text-transform: none;line-height: 30px;">{{ formData.jobName }}</h4>
                                                            <ul class="rbt-meta">
                                                                <li><i class="fa fa-clock"></i> {{ formData.workingType }}</li>
                                                            </ul>
                                                            <ul class="rbt-meta">
                                                                <li><i class="fa fa-tasks"></i> {{ formData.responsibilities }}</li>
                                                            </ul>
                                                            <ul class="rbt-meta">
                                                                <li><i class="fa fa-star"></i> {{ formData.benefits }}</li>
                                                            </ul>

                                                            <div class="rbt-card-bottom">
                                                                <div class="rbt-price rbt-badge-6 bg-secondary-opacity mt-5" style="text-transform: none;">
                                                                    <span class="current-price"><i class="fa fa-wallet"></i> ${{ formData.salaryPaid }} <span style="font-size:18px;">({{ formData.salaryPeriod }})</span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 text-start">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('choise')"><i class="feather-arrow-left"></i> Go Back</a>
                                        </div>
                                        <div class="col-6 text-end">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen('payment')">Continue <i class="feather-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="row g-5" v-if="visibleScreen.payment">
                                        <div class="col-6 text-start">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen(formData.adType)"><i class="feather-arrow-left"></i> Go Back</a>
                                        </div>
                                        <div class="col-6 text-end">
                                            
                                        </div>
                                        <div class="col-12" style="font-size: 14px;">
                                            <div class="row">
                                                <div class="col-5 text-end">
                                                    Total Day: {{ formData.weeks }}<br>
                                                    Daily Fee: ${{ formData.weeklyPrice }}<br>
                                                    Tax: ${{ formData.taxAmount }}<br>
                                                    <hr>
                                                    <b>Total: ${{ formData.totalAmount }}</b><br>
                                                </div>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-5">
                                                    <div class="rbt-form-group">
                                                        <label for="cardNumber">Card Number</label>
                                                        <input id="cardNumber" type="text" v-mask="'#### #### #### ####'" v-model="valueFields.cardNumber" >
                                                    </div>
                                                    <div class="rbt-form-group">
                                                        <label for="cardName">Card Name</label>
                                                        <input id="cardName" type="text" v-model="valueFields.cardName" >
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="rbt-form-group">
                                                                <label for="cardMonth">Month</label>
                                                                <select style="height: 50px;" id="cardMonth" v-model="valueFields.cardMonth">
                                                                    <option value="01">01</option>
                                                                    <option value="02">02</option>
                                                                    <option value="03">03</option>
                                                                    <option value="04">04</option>
                                                                    <option value="05">05</option>
                                                                    <option value="06">06</option>
                                                                    <option value="07">07</option>
                                                                    <option value="08">08</option>
                                                                    <option value="09">09</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="rbt-form-group">
                                                                <label for="cardYear">Year</label>
                                                                <select style="height: 50px;" id="cardYear" v-model="valueFields.cardYear">
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2028">2028</option>
                                                                    <option value="2029">2029</option>
                                                                    <option value="2030">2030</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="rbt-form-group">
                                                                <label for="cardCvv">CVV</label>
                                                                <input id="cardCvv" type="text" v-model="valueFields.cardCvv" @focus="valueFields.flip=true" @blur="valueFields.flip=false">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-7">
                                                    <vue-paycard :value-fields="valueFields" :isCardNumberMasked="false" :hasRandomBackgrounds="false" :class="valueFields.flip ? '-active': ''"/>
                                                </div>
                                            </div> -->
                                            <div class="row mt-2">
                                                <div class="col-12 text-end" >
                                                    <form id="payment-form" action="javascript:;">
                                                        <div id="card-container"></div>
                                                        <a id="card-button" class="rbt-btn" href="javascript:;" ref="card_button" v-if="!square.isProcessing"><i class="fa fa-credit-card"></i> PAY NOW</a>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 text-start">
                                            <a class="rbt-btn-link" href="javascript:;" @click="goToScreen(formData.adType)"><i class="feather-arrow-left"></i> Go Back</a>
                                        </div>
                                        <div class="col-6 text-end">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import { query, collection, getDocs, where, doc, getDoc, addDoc, serverTimestamp } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL  } from 'firebase/storage';
import db from '../firebase/init';
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import EmployerMenu from '../components/EmployerMenu';
import Datepicker from 'vue3-datepicker';
import VuePaycard from 'vue-paycard';
import Swal from 'sweetalert2';
import { mask } from 'vue-the-mask'

export default {
    components: {
        EmployerMenu,
        Datepicker,
        VuePaycard
    },
    directives: {
        mask,
    },
    data() {
        return {
            square: {
                url: process.env.VUE_APP_SQUARE_JS_URL,
                appId: process.env.VUE_APP_SQUARE_APP_ID,
                locationId: process.env.VUE_APP_SQUARE_LOCATION_ID,
                cardIsCreated: false,
                isProcessing: false
            },
            visibleScreen :{
                choise: true,
                upload: false,
                design: false,
                payment: false,
            },
            locationItems: [],
            formData:{
                userId: "",
                companyName: "",
                companyPhotoUrl: "",
                locationId: "",
                locationName: "",
                locationUrl: "",
                weeklyPrice: 0,
                adType: 'image', //image/desing
                startDate: new Date(),
                endDate: this.generateEndDate(5),
                weeks: 0,
                taxAmount: 0,
                totalAmount: 0,
                jobName: "",
                imageUrl: "assets/images/admin/460x280.jpg",
                redirectUrl: "",
                redirectCount: 0,
                workingType: "Full-Time",
                salaryPeriod: "Annual",
                salaryPaid: 0,
                responsibilities: "",
                benefits: "",
                orderNumber: 999,
                status: "pending",
                paymentStatus: "pending"
            },
            valueFields: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                flip: ''
            },
            selectedImage: null,
        };
    },
    created() {
        const script = document.createElement('script');
        script.src = this.square.url;
        script.onload = () => {
            console.log('Square js imported');
        };
        document.head.appendChild(script);

    },
    mounted() {
        this.getUserAuth();
        this.getLocations();
    },
    methods:{
        async getUserAuth(){
            this.$setLoading(true);
            const auth = getAuth();
            onAuthStateChanged(auth, async (user) => {
                this.$setLoading(false);
                if (user) {
                    const userData = await this.getUserProfile(user.uid);

                    if (userData) {
                        this.formData.userId = user.uid;
                        this.formData.companyName = userData.companyName;
                        this.formData.companyPhotoUrl = userData.photoUrl;
                    }
                }
            });
        },
        async getUserProfile(id){
            this.$setLoading(true);
            const documentRef = doc(getFirestore(), 'users', id);
            const documentSnapshot = await getDoc(documentRef);
            this.$setLoading(false);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            
            return null;
        },
        async getLocations() {

            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'locations'), where('status','==',true)));
            this.$setLoading(false);

            this.locationItems = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.data().name,
                    weeklyPrice: doc.data().weeklyPrice,
                    url: doc.data().url,
                };
            });

            if(this.locationItems.length>0){
                this.formData.locationId=this.locationItems[0].id;
                this.setLocation();
            }
        },
        setLocation(){
            var loc = this.locationItems.find(item => item.id == this.formData.locationId);
            this.formData.locationId=loc.id;
            this.formData.locationName=loc.name;
            this.formData.locationUrl=loc.url;
            this.formData.weeklyPrice=loc.weeklyPrice;
        },
        goToScreen(_screen){
            if(_screen == 'choise'){
                this.visibleScreen.choise = true;
                this.visibleScreen.upload = false;
                this.visibleScreen.design = false;
                this.visibleScreen.payment = false;
            }else if(_screen == 'upload'){
                this.visibleScreen.choise = false;
                this.visibleScreen.upload = true;
                this.visibleScreen.design = false;
                this.visibleScreen.payment = false;

                this.formData.adType = 'image';
            }else if(_screen == 'design'){
                this.visibleScreen.choise = false;
                this.visibleScreen.upload = false;
                this.visibleScreen.design = true;
                this.visibleScreen.payment = false;

                this.formData.adType = 'design';
            }else if(_screen == 'payment'){

                this.formData.weeks = this.getDayDifference();
                let totalAmount = parseFloat(this.formData.weeks) * parseFloat(this.formData.weeklyPrice);
                this.formData.taxAmount = parseFloat(totalAmount)*0.10;
                this.formData.totalAmount = parseFloat(totalAmount)+parseFloat(this.formData.taxAmount);

                if(this.formData.userId=="" || this.formData.companyName==""){

                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('userLoginProblem'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }

                if(this.formData.weeks < 1){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('dateSelectionIncorrect'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }

                if(this.formData.locationId=="" || this.formData.locationId==null){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('locationNotFound'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }

                if(this.formData.adType=="design" && (this.formData.jobName=="" || this.formData.redirectUrl=="" || this.formData.workingType=="" || this.formData.salaryPeriod=="" || this.formData.salaryPaid=="" || this.formData.responsibilities=="" || this.formData.benefits=="")){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('fillInAllTheFields'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }
                
                if(this.formData.adType=="image" && (this.formData.jobName=="" || this.formData.redirectUrl=="" || this.formData.imageUrl=="assets/images/admin/460x280.jpg" || this.formData.imageUrl=="")){
                    
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('fillInAllTheFields'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }

                //console.log(this.formData.adType);
                this.visibleScreen.choise = false;
                this.visibleScreen.upload = false;
                this.visibleScreen.design = false;
                this.visibleScreen.payment = true;

                if(!this.cardIsCreated){
                    this.cardIsCreated = true;
                    this.initializeCard();
                }

            }
        },
        async initializeCard() {

            this.$setLoading(true);
            const payments = window.Square.payments(this.square.appId, this.square.locationId);
            let card = await payments.card();        // this card is a listener which cannot be stored as a data element
            await card.attach("#card-container");
            this.$setLoading(false);

            var self = this
            this.$refs.card_button.addEventListener("click", async function (event) {

                const tokenResult = await card.tokenize();
                self.submitForm(tokenResult);
            })
        },
        async submitForm(tokenResult){
            if(!this.square.isProcessing){
                this.square.isProcessing = true;

                this.$setLoading(true);
                if ((tokenResult.status == "OK") && (tokenResult.token.length)) {
                    const _sourceId = tokenResult.token;
                    console.log("token",_sourceId);
                    
                    const documentRef = collection(getFirestore(), 'jobs');
                    const docRef = await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                    console.log("jobId",docRef.id)

                    await this.sendPayment(_sourceId,docRef.id);

                } else{
                    this.square.isProcessing = false;
                    this.$setLoading(false);
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('cardInformationNotVerified'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });

                    return;
                }
            }
        },
        sendPayment(_sourceId,_jobId){
            const functions = getFunctions();
            httpsCallable(functions,"createPayment")({sourceId: _sourceId, jobId: _jobId}).then(async result => {
                this.square.isProcessing = false;
                this.$setLoading(false);
                if(result.data.success==true){
                
                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('saveSuccessful'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    }).then((result) => {
                        this.$router.push('/employer-posts');
                    });
                }else{
                    
                    Swal.fire({
                        title: this.$t('error'),
                        text: result.data.message,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }
                
            })
            .catch(error => {
                this.square.isProcessing = false;
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            });
        },
        handleFileChange(event) {
            this.selectedImage = event.target.files[0];
            
            this.uploadImage();
        },
        async uploadImage(){
            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'jobs_'+randomNumber+'.png');

            try {
                this.$setLoading(true);
                await uploadBytes(storageReference, this.selectedImage);
                const url = await getDownloadURL(storageReference);
                this.formData.imageUrl = url;
                this.selectedImage = null;
                this.$setLoading(false);
            } catch (error) {
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        generateEndDate(days) {
            const result = new Date();
            result.setDate(result.getDate() + days);
            return result;
        },
        getDayDifference() {
            const startDate = new Date(this.formData.startDate);
            const endDate = new Date(this.formData.endDate);

            const startTime = startDate.getTime();
            const endTime = endDate.getTime();

            const timeDifference = endTime - startTime;

            const dayDifference = timeDifference / (1000 * 3600 * 24);

            return Math.round(dayDifference);
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
    
    }
}
</script>
<style>
.rbt-form-group label{
    font-weight: bold;
    font-size: 12px;
}
</style>