import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import db from './firebase/init';
//import firebase from 'firebase/app';
import router from './router'
import LoadingHelper from './helper/LoadingHelper';
import i18n from './helper/i18n';
import scrollHelper from './helper/ScrollHelper';
import DateHelper from './helper/DateHelper';
//import CKEditor from '@ckeditor/ckeditor5-vue';
//import PhoneHelper from './helper/PhoneHelper';
//import ExcelExportHelper from "@/helper/ExcelExportHelper";

const app = createApp(App).use(i18n)

/*const firebaseConfig = {
    apiKey: "AIzaSyB3kMJIealMfMISr_nK0d7HNwOQUKWvSMY",
    authDomain: "peakcareer-66.firebaseapp.com",
    projectId: "peakcareer-66",
    storageBucket: "peakcareer-66.appspot.com",
    messagingSenderId: "131588709200",
    appId: "1:131588709200:web:f8b0ab3dec55cb1c022a55",
    measurementId: "G-7FWCTT7155"   
}


firebase.initializeApp(firebaseConfig);*/


// use router
app.use(router)
app.use(i18n)
app.use(scrollHelper)
app.use(LoadingHelper)
app.use(DateHelper)

//app.use(CKEditor)
//app.use(PhoneHelper)
//app.use(ExcelExportHelper)
app.mount('#app')



import "bootstrap/dist/js/bootstrap.js"
