<template>
    <div class="loading" v-if="loading"><div class="loader"></div></div>
    <router-view></router-view>
</template>
<script>

import { watch } from 'vue';
export default {
    components: {
        
    },
    data() {
        return {
            loading: false,
        }
    },
    mounted(){

    },
    created() {
        watch(() => this.$getLoading(),
            (newValue, oldValue) => {
                this.loading = newValue;
            }
        );
    },
    methods: {

    }
}
</script>