<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <EmployerMenu :activeMenu="'profile'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <!-- Start Instructor Profile  -->
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box">
                                <div class="content">

                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">{{ $t("myProfile") }}</h4>
                                    </div>

                                    <div class="advance-tab-button mb--30">
                                        <ul class="nav nav-tabs tab-button-style-2 justify-content-start" id="settinsTab-4" role="tablist">
                                            <li role="presentation">
                                                <a href="#" class="tab-button active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="true">
                                                    <span class="title">{{ $t("profile") }}</span>
                                                </a>
                                            </li>
                                            <li role="presentation">
                                                <a href="#" class="tab-button" id="password-tab" data-bs-toggle="tab" data-bs-target="#password" role="tab" aria-controls="password" aria-selected="false">
                                                    <span class="title">{{ $t("password") }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="tab-content">
                                        <div class="tab-pane fade active show" id="profile" role="tabpanel" aria-labelledby="profile-tab">

                                            <!-- Start Profile Row  -->
                                            <form class="rbt-profile-row rbt-default-form row row--15" @submit.prevent="submitForm">
                                            
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="name">{{ $t("nameSurname") }} <span class="require">(*)</span></label>
                                                        <input id="name" type="text" v-model="formData.name" required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="companyName">{{ $t("companyName") }} <span class="require">(*)</span></label>
                                                        <input id="companyName" type="text" v-model="formData.companyName" required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="email">{{ $t("email") }} <span class="require">(*)</span></label>
                                                        <input id="email" type="email" v-model="formData.email" readonly required />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <label for="displayname" class="">{{ $t("companyLogo") }} (200x200) <span class="require">(*)</span></label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input class="form-control" type="file" id="formFile" @change="handleFileChange">
                                                        </div>
                                                        <div class="col-md-6">
                                                            <img :src="formData.photoUrl == '' ? '/assets/images/no_user_image.png' : formData.photoUrl" style="width: 80px;">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt--20">
                                                    <div class="rbt-form-group">
                                                        <a class="rbt-btn btn-gradient" href="javascript:;" @click="submitForm">{{ $t("update") }}</a>
                                                    </div>
                                                </div>
                                            </form>
                                            <!-- End Profile Row  -->
                                        </div>

                                        <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="password-tab">
                                            <!-- Start Profile Row  -->
                                            <form class="rbt-profile-row rbt-default-form row row--15" @submit.prevent="changePassword">
                                                <div class="col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="currentpassword">{{ $t("currentPassword") }}</label>
                                                        <input id="currentpassword" type="password" placeholder="Current Password" v-model="formDataPassword.currentPassword">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="newpassword">{{ $t("newPassword") }}</label>
                                                        <input id="newpassword" type="password" placeholder="New Password" v-model="formDataPassword.newPassword">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="rbt-form-group">
                                                        <label for="retypenewpassword">{{ $t("reTypeNewPassword") }}</label>
                                                        <input id="retypenewpassword" type="password" placeholder="Re-type New Password" v-model="formDataPassword.confirmPassword">
                                                    </div>
                                                </div>
                                                <div class="col-12 mt--10">
                                                    <div class="rbt-form-group">
                                                        <a class="rbt-btn btn-gradient" href="javascript:;" @click="changePassword">{{ $t("updatePassword") }}</a>
                                                    </div>
                                                </div>
                                            </form>
                                            <!-- End Profile Row  -->
                                        </div>

                                    </div>





                                </div>
                            </div>
                            <!-- End Instructor Profile  -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import EmployerMenu from '../components/EmployerMenu';
import { doc, getDoc, updateDoc  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL  } from 'firebase/storage';
import {
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential, 
  EmailAuthProvider, 
  updatePassword
} from "firebase/auth";
import Swal from 'sweetalert2';

export default {
    components: {
        EmployerMenu
    },
    data() {
        return {
            formData: {
                id: '',
                companyName: '',
                name: '',
                email: '',
                photoUrl: ''
            },
            formDataPassword:{
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            selectedImage: null,
        };
    },
    async mounted() {
        this.$setLoading(true);
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userData = await this.getUserProfile(user.uid);
                this.$setLoading(false);
                if (userData) {
                    this.formData = { ...userData };
                    this.formData.id = user.uid;
                }
            }
        });
    },  
    methods:{
        async getUserProfile(id) {
            
            const documentRef = doc(getFirestore(), 'users', id);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {

            if(this.formData.id == "" || this.formData.name=="" || this.formData.companyName=="" || this.formData.email==""){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('fillInAllTheFields'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                try {
                    this.$setLoading(true);
                    const documentRef = doc(getFirestore(), 'users', this.formData.id);
                    await updateDoc(documentRef, { ...this.formData });
                    this.$setLoading(false);

                    localStorage.setItem("name", this.formData.name);
                    localStorage.setItem("companyName", this.formData.companyName);
                    localStorage.setItem("photoUrl", this.formData.photoUrl);

                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('saveSuccessful'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });
                    
                } catch (error) {
                    this.$setLoading(false);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }
            }
        },
        handleFileChange(event) {
            this.selectedImage = event.target.files[0];
            
            this.uploadImage();
        },
        async uploadImage(){
            const randomNumber = this.generateRandomNumber();
            const storage = getStorage();
            const storageReference = storageRef(storage, 'profile_'+randomNumber+'.png');

            try {
                this.$setLoading(true);
                await uploadBytes(storageReference, this.selectedImage);
                const url = await getDownloadURL(storageReference);
                this.formData.photoUrl = url;
                this.selectedImage = null;
                this.$setLoading(false);

                localStorage.setItem("photoUrl", this.formData.photoUrl)
            } catch (error) {
                this.$setLoading(false);
                Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });
            }
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async changePassword(){

            if(this.formDataPassword.currentPassword == "" || this.formDataPassword.newPassword=="" || this.formDataPassword.confirmPassword==""){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('fillInAllTheFields'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.formDataPassword.newPassword != this.formDataPassword.confirmPassword){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('passwordDoNotMatch'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.formDataPassword.newPassword.length < 6){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('passwordMinCharacter'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.formDataPassword.currentPassword == this.formDataPassword.newPassword){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('passwordsSameMessage'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                try {
                    this.$setLoading(true);
                    const auth = getAuth();
                    const user = auth.currentUser;

                    const credential = EmailAuthProvider.credential(user.email, this.formDataPassword.currentPassword);
                    await reauthenticateWithCredential(user, credential);

                    await updatePassword(user, this.formDataPassword.newPassword);

                    this.formDataPassword.currentPassword = "";
                    this.formDataPassword.newPassword = "";
                    this.formDataPassword.confirmPassword = "";
                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('passwordUpdated'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });

                    this.$setLoading(false);

                }catch(error){
                    this.$setLoading(false);
                    console.log("error",error);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                };
            }

        }
    }
}
</script>
