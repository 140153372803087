<template>
<div style="text-align: center;padding-top: 10%;">
    <p>{{ $t("directedMessage") }}</p>
    <p class="loader"></p>
</div>
</template>
<script>

import { doc, getDoc,  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from 'sweetalert2';
export default {
    components: {
        
    },
    data() {
        return {
            job: null
        };
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id=="" || id=="0"){
            Swal.fire({
                title: this.$t('error'),
                text: this.$t('missingParameter'),
                icon: 'error',
                confirmButtonText: this.$t('ok')
            });
        }else{

            this.job = await this.getJob(id);
            console.log(this.job);

            const functions = getFunctions();
            
            httpsCallable(functions,"increaseJobRedirectCount")({ jobId: id}).then(async result => {
                if(result.data.success==true){
                    window.location.href = this.job.redirectUrl;
                }else{
                    console.log(result.data);
                    /*Swal.fire({
                        title: this.$t('error'),
                        text: result.data.message,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });*/
                }
                
            })
            .catch(error => {
                console.log(error);
                /*Swal.fire({
                    title: this.$t('error'),
                    text: error,
                    icon: 'error',
                    confirmButtonText: this.$t('ok')
                });*/
            });

            

        }
    },  
    methods:{
        async getJob(jobId){
            const documentRef = doc(getFirestore(), 'jobs', jobId);
            const documentSnapshot = await getDoc(documentRef);

            if (documentSnapshot.exists()) {
                return {
                    id: jobId,
                    redirectUrl: documentSnapshot.data().redirectUrl
                };
            }
            return null;
        }
    }
}
</script>