<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <AdminMenu :activeMenu="'liveTv'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">{{ $t("liveTv") }}</h4>
                                    </div>
                                    <div class="row g-5">
                                        
                                        <div class="col-4">
                                            <div class="section-title text-center">
                                                <div class="filter-select-option" style="font-size: 14px;">
                                                    <div class="filter-select rbt-modern-select">
                                                        <span class="select-label d-block">LOCATIONS</span>
                                                        <select v-model="selectLocation.id" @change="setLocation" >
                                                            <option v-for="location in items" :value="location['id']" :key="location['id']">{{ location['name'] }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8" style="font-size: 16px;padding-top: 40px;">
                                            <a :href="baseUrl+'/#/live/'+selectLocation.url" target="_blank" v-if="selectLocation.url!=''"><i class="fa fa-link"></i> {{ baseUrl+'/#/live/'+selectLocation.url }}</a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import AdminMenu from '../components/AdminMenu';
import { query, collection, getDocs, where } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';

export default {
    components: {
        AdminMenu
    },
    data() {
        return {
            items: [],
            selectLocation:{
                id:"",
                name:"",
                url:""
            },
            baseUrl: process.env.VUE_APP_BASE_URL
        };
    },
    mounted() {
        this.getLocations();
    },  
    methods: {
        async getLocations() {

            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'locations'),where('status','==',true)));
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.data().name,
                    url: doc.data().url,
                };
            });
        },
        setLocation(){
            var loc = this.items.find(item => item.id == this.selectLocation.id);
            this.selectLocation.id=loc.id;
            this.selectLocation.name=loc.name;
            this.selectLocation.url=loc.url;
        }
    },
}
</script>