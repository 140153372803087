<template>
        <!-- Start Banner Area -->
        <div class="rbt-banner-area rbt-banner-1" id="employerHome">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 pb--120 pt--70">
                        <div class="content">
                            <div class="inner">

                                <h1 class="title">
                                    {{ $t('employerDefaultPageTitle') }}
                                </h1>
                                <p class="description">
                                    {{ $t('employerDefaultPageDescription') }}
                                </p>
                                <div class="slider-btn">
                                    <a class="rbt-btn btn-gradient hover-icon-reverse" href="#/login">
                                        <span class="icon-reverse-wrapper">
                                            <span class="btn-text">{{ $t('tryItNow') }}</span>
                                        <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                        <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div class="shape-wrapper" id="scene">
                                <img src="assets/images/icons/tv.png">
                                <div class="hero-bg-shape-1 layer" data-depth="0.4">
                                    <img src="assets\images\shape\shape-01.png">
                                </div>
                                <div class="hero-bg-shape-2 layer" data-depth="0.4">
                                    <img src="assets\images\shape\shape-02.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Banner Area -->

        <!-- Start About  -->
        <div class="rbt-video-area bg-color-white rbt-section-gap" id="howItWorks">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6">
                    <div class="video-popup-wrapper">
                        <img class="w-100 rbt-radius" src="assets\images\others\video-05.jpg" alt="Video Images">
                        <a class="rbt-btn rounded-player position-to-top" href="https://www.youtube.com/" target="_blank">
                            <span><i class="feather-play"></i></span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0">
                        <div class="section-title text-start">
                            <h4 class="title">{{ $t("howItWorks") }}</h4>
                            <p class="description mt--30 mt_md--15 mt_sm--15 mb_md--15 mb_sm--15">{{ $t("howItWorksDescription") }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About  -->

    <!-- Start Service Area -->
    <div class="rbt-service-area bg-color-white" style="margin-bottom: 100px;">
        <div class="container">
            <div class="row mb--60 g-5 align-items-end">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="section-title text-start">
                        <h4 class="title">{{ $t("processSteps") }}</h4>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="read-more-btn text-start text-md-end">
                        <a class="rbt-moderbt-btn" href="#/login">
                            <span class="moderbt-btn-text" style="font-size: 14px;">{{ $t("tryItNow") }}</span>
                            <i class="feather-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <!-- Start Card Area -->
            <div class="row row--15 mt_dec--30">

                <!-- Start Service Grid  -->
                <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div class="service-card service-card-6">
                        <div class="inner">
                            <div class="icon">
                                <img src="assets\images\icons\001-bulb.png">
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="javascript:;">{{ $t("step1Title") }}</a></h6>
                                <p class="description">{{ $t("step1Description") }}</p>
                            </div>
                            <span class="number-text">1</span>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

                <!-- Start Service Grid  -->
                <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div class="service-card service-card-6">
                        <div class="inner">
                            <div class="icon">
                                <img src="assets\images\icons\002-hat.png">
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="javascript:;">{{ $t("step2Title") }}</a></h6>
                                <p class="description">{{ $t("step2Description") }}</p>
                            </div>
                            <span class="number-text">2</span>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

                <!-- Start Service Grid  -->
                <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div class="service-card service-card-6">
                        <div class="inner">
                            <div class="icon">
                                <img src="assets\images\icons\003-id-card.png">
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="javascript:;">{{ $t("step3Title") }}</a></h6>
                                <p class="description">{{ $t("step3Description") }}</p>
                            </div>
                            <span class="number-text">3</span>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

                <!-- Start Service Grid  -->
                <div class="col-lg-4 col-xl-3 col-xxl-3 col-md-6 col-sm-6 col-12 mt--30">
                    <div class="service-card service-card-6">
                        <div class="inner">
                            <div class="icon">
                                <img src="assets\images\icons\004-pass.png">
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="javascript:;">{{ $t("step4Title") }}</a></h6>
                                <p class="description">{{ $t("step4Description") }}</p>
                            </div>
                            <span class="number-text">4</span>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

            </div>
            <!-- End Card Area -->
        </div>
    </div>
    <!-- End Service Area -->

    <div class="rbt-pricing-area" style="background-color: rgb(203, 214, 255);padding-bottom: 40px;" id="pricing">
        <div class="container">
            <div class="row g-5 mb--60">
                <div class="col-12">
                    <div class="section-title text-center">
                        <span class="subtitle bg-pink-opacity">START HIRING SMARTER TODAY</span>
                        <div class="filter-select-option" style="font-size: 14px;">
                            <div class="filter-select rbt-modern-select">
                                <h3 class="select-label d-block" style="opacity: 1;">LOCATIONS</h3>
                                <select v-model="selectLocation.id" @change="setLocation" >
                                    <option v-for="location in locationItems" :value="location['id']" :key="location['id']">{{ location['name'] }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="advance-pricing">
                        <div class="inner">
                            <div class="row row--0">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="pricing-left">
                                        <h3 class="main-title text-center">{{ selectLocation.name }}</h3>
                                        <p class="description"></p>
                                        <div class="price-wrapper">
                                            <span class="price-amount">${{ selectLocation.weeklyPrice }}<sup>/daily</sup></span>
                                        </div>
                                        <div class="pricing-btn-group">
                                            <a href="#/login" class="rbt-btn btn-gradient w-100 text-center">Purchase Now</a>
                                        </div>
                                        <div class="rating">
                                            <a href="#rating">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewbox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                            </a>
                                            <a href="#rating">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewbox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                            </a>
                                            <a href="#rating">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewbox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                            </a>
                                            <a href="#rating">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewbox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                            </a>
                                            <a href="#rating">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewbox="0 0 16 16">
                                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                                                </svg>
                                            </a>
                                        </div>
                                        <small class="subtitle">All those who preferred it were satisfied.</small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="pricing-right position-relative">
                                        <div class="pricing-offer">
                                            <div class="single-list">
                                                <h4 class="price-title">Plan content</h4>
                                                <ul class="plan-offer-list">
                                                    <li>
                                                        <i class="feather-check"></i> Pre made templates
                                                    </li>
                                                    <li>
                                                        <i class="feather-check"></i> Visibility in high traffic locations
                                                    </li>
                                                    <li>
                                                        <i class="feather-check"></i> 24/7 Broadcasting
                                                    </li>
                                                    <li>
                                                        <i class="feather-check"></i> 24/7 Moniture your applications
                                                    </li>
                                                    <li>
                                                        <i class="feather-check"></i> Upload your own hiring flyer
                                                    </li>
                                                    <!-- <li class="off">
                                                        <i class="feather-x"></i> 24/7 Dedicated Support
                                                    </li> -->
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="pricing-badge"><span>BEST PRICE</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Start FAQ  -->
    <div class="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gap" id="faq">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-12 text-center">
                    <h1>{{ $t("frequentlyAskedQuestions") }}</h1>
                </div>
                <div class="col-lg-6" style="font-size: 14px;">
                    <div class="rbt-accordion-style accordion">
                        <div class="rbt-accordion-style rbt-accordion-04 accordion">
                            <div class="accordion" id="accordionExamplec3">
                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingThree1">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree1" aria-expanded="true" aria-controls="collapseThree1">
                                            {{ $t("employerFaq1Question") }}
                                        </button>
                                    </h2>
                                    <div id="collapseThree1" class="accordion-collapse collapse show" aria-labelledby="headingThree1" data-bs-parent="#accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq1Answer") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingThree2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree2" aria-expanded="false" aria-controls="collapseThree2">
                                            {{ $t("employerFaq2Question") }}
                                        </button>
                                    </h2>
                                    <div id="collapseThree2" class="accordion-collapse collapse" aria-labelledby="headingThree2" data-bs-parent="#accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq2Answer") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingThree3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree3" aria-expanded="false" aria-controls="collapseThree3">
                                            {{ $t("employerFaq3Question") }}
                                        </button>
                                    </h2>
                                    <div id="collapseThree3" class="accordion-collapse collapse" aria-labelledby="headingThree3" data-bs-parent="#accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq3Answer") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="headingThree4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                                            {{ $t("employerFaq4Question") }}
                                        </button>
                                    </h2>
                                    <div id="collapseThree4" class="accordion-collapse collapse" aria-labelledby="headingThree4" data-bs-parent="#accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq4Answer") }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6" style="font-size: 14px;">
                    <div class="rbt-accordion-style accordion">
                        <div class="rbt-accordion-style rbt-accordion-04 accordion">
                            <div class="accordion" id="faqs-accordionExamplec3">
                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="faqs-headingThree1">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapseThree1" aria-expanded="true" aria-controls="faqs-collapseThree1">
                                            {{ $t("employerFaq5Question") }}
                                        </button>
                                    </h2>

                                    <div id="faqs-collapseThree1" class="accordion-collapse collapse show" aria-labelledby="faqs-headingThree1" data-bs-parent="#faqs-accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq5Answer") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="faqs-headingThree2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapseThree2" aria-expanded="false" aria-controls="faqs-collapseThree2">
                                            {{ $t("employerFaq6Question") }}
                                        </button>
                                    </h2>
                                    <div id="faqs-collapseThree2" class="accordion-collapse collapse" aria-labelledby="faqs-headingThree2" data-bs-parent="#faqs-accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq6Answer") }}
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item card">
                                    <h2 class="accordion-header card-header" id="faqs-headingThree3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapseThree3" aria-expanded="false" aria-controls="faqs-collapseThree3">
                                            {{ $t("employerFaq7Question") }}
                                        </button>
                                    </h2>
                                    <div id="faqs-collapseThree3" class="accordion-collapse collapse" aria-labelledby="faqs-headingThree3" data-bs-parent="#faqs-accordionExamplec3">
                                        <div class="accordion-body card-body">
                                            {{ $t("employerFaq7Answer") }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End FAQ  -->

</template>
<script>

import { query, collection, getDocs, where } from "firebase/firestore";
import db from '../firebase/init';
export default {
    components: {
    
    },
    data() {
        return {
            selectLocation:{
                id: 0,
                name: "",
                weeklyPrice: 0
            },
            locationItems: []
        };
    },
    async mounted() {
        this.getLocations();
    },  
    methods:{
        async getLocations() {

            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'locations'), where('status','==',true)));
            this.$setLoading(false);
            
            this.locationItems = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.data().name,
                    weeklyPrice: doc.data().weeklyPrice
                };
            });

            if(this.locationItems.length>0){
                this.selectLocation.id=this.locationItems[0].id;
                this.setLocation();
            }
        },
        setLocation(){
            var loc = this.locationItems.find(item => item.id == this.selectLocation.id);
            this.selectLocation.id=loc.id;
            this.selectLocation.name=loc.name;
            this.selectLocation.weeklyPrice=loc.weeklyPrice;
        },
        gogogo(id){
            console.log("asd");

            console.log('Supports scroll behavior:', 'scrollBehavior' in document.documentElement.style);


            const targetElement = document.getElementById(id);
            if (targetElement) {
              const topOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
              console.log('Target element:', targetElement);
              console.log('Top offset:', topOffset);
              window.scrollTo({
                top: topOffset,
                behavior: 'smooth'
              });
            } else {
              console.error(`Element with id "${id}" not found.`);
            }
        }
    }
}
</script>