<template>

    <!-- Start Newsletter Area  -->
    <div class="rbt-newsletter-area bg-color-white" style="background-color: rgb(249, 249, 255) !important;padding: 120px;" id="workerHome">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <form class="newsletter-form-1 radius-round" @submit.prevent="searchJob">
                            <input class="rbt-border" type="text" v-model="searchWord" :placeholder="$t('findJob')">
                            <button type="submit" class="rbt-btn btn-md btn-gradient hover-icon-reverse radius-round">
                                <span class="icon-reverse-wrapper">
                <span class="btn-text">{{ $t('search') }}</span>
                                <span class="btn-icon"><i class="fa fa-search"></i></span>
                                <span class="btn-icon"><i class="fa fa-search"></i></span>
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Newsletter Area  -->

    <!-- Start About  -->
    <div class="rbt-about-area bg-color-white rbt-section-gap" id="about">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 order-2 order-lg-1">
                    <div class="inner">
                        <div class="section-title text-start">
                            <span class="subtitle bg-primary-opacity">{{ $t("about") }}</span>
                            <h2 class="title">{{ $t("aboutPeakCareer") }}</h2>
                            <p class="description mt--20">{{ $t("aboutPeakCareerDescription") }}</p>
                        </div>
                        <div class="section-title text-start" style="margin-top: 20px;">
                            <h2 class="title">{{ $t("ourMission") }}</h2>
                            <p class="description mt--20">{{ $t("ourMissionDescription") }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 order-1 order-lg-2">
                    <div class="content">
                        <img src="assets\images\about\bg-about.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End About  -->

    <!-- Start Course Area -->
    <div class="rbt-course-area bg-color-extra2 rbt-section-gap" id="newjobs">
        <div class="container">
            <div class="row mb--60">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <span class="subtitle bg-secondary-opacity">{{ $t('recentlyAddedJobs') }}</span>
                    </div>
                </div>
            </div>
            <!-- Start Card Area -->
            <div class="row g-5">
                <!-- Start Single Course  -->
                <a class="col-4" v-for="item in items" :key="item.id" :href="'#/view/'+item.id" target="_blank">
                    <JobItem :jobItem="item" :isTv="false"></JobItem>
                </a>
                <!-- End Single Course  -->

            </div>
            <!-- End Card Area -->

            <div class="row">
                <div class="col-lg-12">
                    <div class="load-more-btn mt--60 text-center">
                        <a class="rbt-btn btn-gradient btn-lg hover-icon-reverse" href="#/jobs">
                            <span class="icon-reverse-wrapper">
                                <span class="btn-text">{{ $t('loadMoreJobs') }}</span>
                            <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                            <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Course Area -->

    <!-- Start FAQ  -->
    <div class="rbt-accordion-area accordion-style-1 bg-color-white rbt-section-gapTop" id="faq">
        <div class="container">
            <div class="row mb--60">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <span class="subtitle bg-pink-opacity">{{ $t("faq") }}</span>
                        <h2 class="title">{{ $t("frequentlyAskedQuestions") }}</h2>
                    </div>
                </div>
            </div>
            <div class="row g-5 align-items-start">
                <div class="col-lg-7 order-2 order-lg-1">
                    <div class="rbt-accordion-style rbt-accordion-01 rbt-accordion-06 accordion">
                        <div class="accordion" id="tutionaccordionExamplea1">
                            <div class="accordion-item card">
                                <h2 class="accordion-header card-header" id="tutionheadingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tutioncollapseOne" aria-expanded="true" aria-controls="tutioncollapseOne">
                                        {{ $t("workerFaq1Question") }}
                                    </button>
                                </h2>
                                <div id="tutioncollapseOne" class="accordion-collapse collapse show" aria-labelledby="tutionheadingOne" data-bs-parent="#tutionaccordionExamplea1">
                                    <div class="accordion-body card-body">
                                        <p>{{ $t("workerFaq1Answer") }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item card">
                                <h2 class="accordion-header card-header" id="tutionheadingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tutioncollapseTwo" aria-expanded="false" aria-controls="tutioncollapseTwo">
                                        {{ $t("workerFaq2Question") }}
                                    </button>
                                </h2>
                                <div id="tutioncollapseTwo" class="accordion-collapse collapse" aria-labelledby="tutionheadingTwo" data-bs-parent="#tutionaccordionExamplea1">
                                    <div class="accordion-body card-body">
                                        <p>{{ $t("workerFaq2Answer") }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item card">
                                <h2 class="accordion-header card-header" id="tutionheadingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tutioncollapseThree" aria-expanded="false" aria-controls="tutioncollapseThree">
                                        {{ $t("workerFaq3Question") }}
                                    </button>
                                </h2>
                                <div id="tutioncollapseThree" class="accordion-collapse collapse" aria-labelledby="tutionheadingThree" data-bs-parent="#tutionaccordionExamplea1">
                                    <div class="accordion-body card-body">
                                        <p>{{ $t("workerFaq3Answer") }}</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 order-1 order-lg-2">
                    <div class="thumbnail">
                        <img class="radius-6" src="assets\images\about\about-07.png" alt="histudy image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End FAQ  -->
</template>
<script>

import { getDocs, query, orderBy, where, limit, collection  } from "firebase/firestore";
import db from '../firebase/init';
import JobItem from '../components/JobItem';
export default {
    components: {
        JobItem
    },
    data() {
        return {
            items: [],
            searchWord: ""
        };
    },
    async mounted() {
        this.getLastJobs();
    },  
    methods:{

        async getLastJobs() {
            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'jobs'), where('status','==','active'), orderBy('createdDate', 'desc'), limit(3)));
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                };
            });
        },

        searchJob(){
            this.$router.push({ name: 'jobs', query: { jobName: this.searchWord } });
        }
    }
}
</script>