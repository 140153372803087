<template>
<div class="container">
    
    <p style="margin-top: 20px;">
        <a class="rbt-btn-link" href="#/"><i class="feather-arrow-left"></i> Go Home</a>
    </p>
    <h1>Peak Career Service Policy</h1>

    <p>
        <b>Introduction:</b><br>
        Welcome to {{ baseUrl }}, the marketing platform operated by YASAM LLC. Our platform provides innovative advertising solutions, allowing businesses to showcase their products and services in high-traffic public areas. This Service Policy governs the use of our services. By accessing or using our website, you agree to comply with these terms and conditions. If you do not agree with any part of this policy, you may not access or use our services.
    </p>
    
    <p>
        <b>Service Description:</b><br>
        {{ baseUrl }} facilitates the sale of advertising space on television screens located in prominent public areas such as malls, markets, and streets. In addition to traditional advertising, customers can advertise their hiring signs on these areas to attract talent for their businesses.
    </p>

    <p>
        <b>Service Eligibility:</b><br>
        Users agree to use our services solely for lawful advertising purposes and in accordance with this Service Policy and all applicable laws and regulations. Users are prohibited from engaging in any activity that may:<br>
        - Violate the rights of others<br>
        - Promote illegal or harmful products or services<br>
        - Disrupt public order or safety<br>
        - Compromise the integrity of our advertising network<br>

    </p>

    <p>
        <b>Advertisement Content:</b><br>
        Advertisers are solely responsible for the content of their advertisements, including hiring signs. All advertisements must comply with our content guidelines and standards. We reserve the right to reject or remove any advertisement that we deem inappropriate, offensive, or in violation of our policies.
    </p>

    <p>
        <b>Intellectual Property:</b><br>
        Advertisers retain ownership of the intellectual property rights to their advertisements. By submitting an advertisement for display on our advertising network, advertisers grant us a non-exclusive license to display the advertisement in the designated public locations for the duration of the advertising campaign.
    </p>

    <p>
        <b>Privacy Policy:</b><br>
        We are committed to protecting the privacy of our users and advertisers. Our Privacy Policy outlines how we collect, use, and disclose personal information. By using our services, you consent to the collection and use of your personal information in accordance with our Privacy Policy.
    </p>

    <p>
        <b>Payment Terms:</b><br>
        Advertisers agree to pay the specified fees for the advertising services provided. Payment terms, including pricing, billing cycles, and payment methods, are outlined in the advertising agreement between the advertiser and YASAM LLC.
    </p>

    <p>
        <b>Limitation of Liability:</b><br>
        In no event shall YASAM LLC be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our services or inability to use our services, even if we have been advised of the possibility of such damages.
    </p>

    <p>
        <b>Changes to Service Policy:</b><br>
        We reserve the right to modify or update this Service Policy at any time without prior notice. Users and advertisers are encouraged to review this policy periodically for any changes. Continued use of our services after the posting of changes constitutes acceptance of those changes.
    </p>

    <p>
        <b>Contact Information:</b><br>
        If you have any questions or concerns about this Service Policy, please contact us at peakcareer1@gmail.com.
    </p>

    <p>
        <b>Effective Date:</b><br>
        This Service Policy is effective as of April 1, 2024, and supersedes all prior versions.
    </p>

    <p>
        <br>
    </p>


</div>
</template>
<script>


export default {
    components: {
    
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_URL
        };
    },
    async mounted() {

    },  
    methods:{

    }
}
</script>