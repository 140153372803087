<template>


        <!-- Start breadcrumb Area -->
    <div class="rbt-breadcrumb-default ptb--40 ptb_md--50 ptb_sm--30 bg-gradient-1">
        <div style="text-align: center;margin-bottom: 30px;">
            <a href="#/employer"><img src="assets/images/logo/logo.png" :alt="$t('projectName')" style="max-width: 400px;"></a>
        </div>
    </div>
    <!-- End Breadcrumb Area -->

    <div class="rbt-elements-area bg-color-white rbt-section-gap" style="padding-top: 10px;">
        <div class="container">
            <div class="row gy-5 row--30">

                <div class="col-lg-6" style="float:none;margin:auto;">
                    <div class="rbt-contact-form contact-form-style-1 max-width-auto">
                        <h3 class="title" style="margin-bottom: 15px;">{{ $t("forgotMyPassword") }}</h3>
                        <form class="max-width-auto" @submit.prevent="submit">
                            <div class="form-group">
                                <input name="con_name" type="text" v-model="formData.email">
                                <label>{{ $t("companyEmailAddress") }} *</label>
                                <span class="focus-border"></span>
                            </div>

                            <div class="form-submit-group">
                                <button type="submit" class="rbt-btn btn-md btn-gradient hover-icon-reverse w-100" @click="submit">
                                    <span class="icon-reverse-wrapper">
                                        <span class="btn-text">{{ $t("sendEmail") }}</span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    </span>
                                </button>
                            </div>

                            <div class="row mt--30">
                                <div class="col-12">
                                    <div class="rbt-lost-password text-center">
                                        <a class="rbt-btn-link" href="#/login">{{ $t("returnToLogin") }}</a>
                                    </div>
                                </div>
                            </div>
    
                        </form>
                    </div>

                </div>

            </div>
        </div>
    </div>


</template>
<script>
import {
  getAuth,
  sendPasswordResetEmail 
} from "firebase/auth";
import Swal from 'sweetalert2';
export default {
    components: {
    
    },
    data() {
        return {
            formData: {
                email: ''
            }
        };
    },
    async mounted() {

    },  
    methods:{
        async submit() {
            if(this.formData.email == ''){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('pleaseEnterEmail'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                const email = this.formData.email;

                var mailPattern = new RegExp("^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$");
                if((!mailPattern.test(email))){
                    Swal.fire({
                        title: this.$t('warning'),
                        text: this.$t('invalidEmail'),
                        icon: 'warning',
                        confirmButtonText: this.$t('ok')
                    });
                }else{

                    this.$setLoading(true);
                    const auth = getAuth();

                    sendPasswordResetEmail(auth, email)
                    .then(async () => {
                        this.$setLoading(false);

                        this.formData.email = "";

                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('passwordResetEmailMessage'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                    })
                    .catch((error) =>{
                        this.$setLoading(false);

                        this.formData.email = "";

                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    });
                }

            }
        }
    }
}
</script>