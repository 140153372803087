<template>


<div class="rbt-page-banner-wrapper">
        <!-- Start Banner BG Image  -->
        <div class="rbt-banner-image"></div>
        <!-- End Banner BG Image  -->
        <div class="rbt-banner-content">

            <!-- Start Banner Content Top  -->
            <div class="rbt-banner-content-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Start Breadcrumb Area  -->
                            <ul class="page-list">
                                <li class="rbt-breadcrumb-item"><a href="#/worker">{{ $t('home') }}</a></li>
                                <li>
                                    <div class="icon-right"><i class="feather-chevron-right"></i></div>
                                </li>
                                <li class="rbt-breadcrumb-item active">{{ $t('jobs') }}</li>
                            </ul>
                            <!-- End Breadcrumb Area  -->
                            <div class=" title-wrapper">
                                <h1 class="title mb--0">{{ $t('allJobs') }}</h1>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Banner Content Top  -->

            <!-- Start Course Top  -->
            <div class="rbt-course-top-wrapper mt_sm--20">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-5 col-md-12">
                            <div class="rbt-sorting-list d-flex flex-wrap align-items-center">
                                <div class="rbt-short-item">
                                    <span class="course-index">Showing {{ items.length }} results</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                            <div class="rbt-sorting-list d-flex flex-wrap align-items-center justify-content-start justify-content-lg-end">
                                <div class="rbt-short-item">
                                    <form class="rbt-search-style me-0" @submit.prevent="searchJob">
                                        <input type="text" v-model="searchWord" :placeholder="$t('findJob')">
                                        <button type="submit" class="rbt-search-btn rbt-round-btn">
                                            <i class="feather-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Start Filter Toggle  -->
                    <div class="default-exp-wrapper" style="font-size:14px;">
                        <div class="filter-inner">
                            <div class="filter-select-option">
                                <div class="filter-select rbt-modern-select">
                                    <span class="select-label d-block">Job Location</span>
                                    <select v-model="selectLocationId" @change="getFilteredJobs">
                                        <option value="">All Locations</option>
                                        <option v-for="location in locationItems" :value="location['id']" :key="location['id']">{{ location['name'] }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Filter Toggle  -->
                </div>
            </div>
            <!-- End Course Top  -->
        </div>
    </div>

    <div class="rbt-section-overlayping-top rbt-section-gapBottom">
        <div class="inner">
            <div class="container">
                <!-- Start Card Area -->
                <div class="row g-5">

                    <!-- Start Single Course  -->
                    <a class="col-4" v-for="item in items" :key="item.id" :href="'#/view/'+item.id" target="_blank">
                        <JobItem :jobItem="item" :isTv="false"></JobItem>
                    </a>
                    <!-- End Single Course  -->

                </div>
                <!-- End Card Area -->
            </div>
        </div>
    </div>

</template>
<script>

import { getDocs, query, orderBy, where, limit, collection  } from "firebase/firestore";
import db from '../firebase/init';
import JobItem from '../components/JobItem';
export default {
    components: {
        JobItem
    },
    data() {
        return {
            locationItems: [],
            selectLocationId: "",
            searchWord: "",
            items: []
        };
    },
    async mounted() {
        this.getLocations();
        await this.getFilteredJobs();
    },  
    methods:{
        async getLocations() {
            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'locations'), where('status','==',true)));
            this.$setLoading(false);
            this.locationItems = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.data().name,
                    weeklyPrice: doc.data().weeklyPrice
                };
            });
        },
        async getFilteredJobs() {
            this.$setLoading(true);
            let q = query(collection(db, 'jobs'), where('status','==','active'), orderBy('createdDate', 'desc'));

            /*if(this.searchWord!="" && this.selectLocationId!=""){
                q = query(collection(db, 'jobs'), where('status','==','active'), where('jobName', 'array-contains', this.searchWord), where('locationId', '==', this.selectLocationId), orderBy('createdDate', 'desc'));
            }else if(this.searchWord!=""){
                q = query(collection(db, 'jobs'), where('status','==','active'), where('jobName', 'array-contains', ["account"]), orderBy('createdDate', 'desc'));
            }else if(this.selectLocationId!=""){
                q = query(collection(db, 'jobs'), where('status','==','active'), where('locationId', '==', this.selectLocationId), orderBy('createdDate', 'desc'));
            }*/
            if(this.selectLocationId!=""){
                q = query(collection(db, 'jobs'), where('status','==','active'), where('locationId', '==', this.selectLocationId), orderBy('createdDate', 'desc'));
            }
            
            const querySnap = await getDocs(q);
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                };
            });

            if(this.$route.query.jobName!="" && this.$route.query.jobName!=null && this.$route.query.jobName!=undefined){
                this.searchWord = this.$route.query.jobName;

                const searchWordLower = this.searchWord.toLowerCase();
                this.items = this.items.filter(job => job.jobName.toLowerCase().includes(searchWordLower));
            }
        },
        async searchJob(){
            this.$router.push({ name: 'jobs', query: { jobName: this.searchWord } });
            await this.getFilteredJobs();
        },
    }
}
</script>