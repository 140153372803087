const DateHelper = {
    install(app) {
      app.config.globalProperties.$formatDate = function (timestamp) {
        const jsDate = timestamp.toDate();
        const year = jsDate.getFullYear();
        const month = String(jsDate.getMonth() + 1).padStart(2, '0');
        const day = String(jsDate.getDate()).padStart(2, '0');
        const hours = String(jsDate.getHours()).padStart(2, '0');
        const minutes = String(jsDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      };
    }
  };
  
  export default DateHelper;