import { reactive, readonly } from 'vue';

const LoadingHelper = {
  install(app) {
    const state = reactive({
      loading: false,
    });

    app.config.globalProperties.$getLoading = function () {
      return readonly(state.loading);
    };

    app.config.globalProperties.$setLoading = function (newValue) {
      state.loading = newValue;
    };
  },
};

export default LoadingHelper;