<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <AdminMenu :activeMenu="'locations'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-form-group mb-3">
                                <a class="btn" style="font-size: 14px;" href="#/admin-locations"><i class="fa fa-angle-left"></i> {{ $t("backToLocations") }}</a>
                            </div>

                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">{{ $route.params.id==0 ? $t('locationAdd') : $t('locationEdit') }}</h4>
                                    </div>
                                    <div class="row mt-5">

                                        <form class="rbt-profile-row rbt-default-form row row--15" @submit.prevent="submitForm">
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="rbt-form-group">
                                                    <label for="name">{{ $t("locationName") }} <span class="require">(*)</span></label>
                                                    <input id="name" type="text" v-model="formData.name" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="rbt-form-group">
                                                    <label for="url">{{ $t("url") + ' ' +baseUrl+'/#/live/'}} <span class="require">(*)</span></label>
                                                    <input id="url" type="text" v-model="formData.url" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="rbt-form-group">
                                                    <label for="weeklyPrice">{{ $t("weeklyPrice") }} ($) <span class="require">(*)</span></label>
                                                    <input id="weeklyPrice" type="number" v-model="formData.weeklyPrice" required />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="rbt-checkbox-wrapper">
                                                    <label class="mb-4">{{ $t("isActive") }}</label>
                                                    <input id="status" name="status" type="checkbox" v-model="formData.status">
                                                    <label for="status">{{ $t("active") }}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 mt--20">
                                                <div class="rbt-form-group">
                                                    <a class="rbt-btn btn-gradient" href="javascript:;" @click="submitForm"><i class="fa fa-save"></i> {{ $route.params.id==0 ? $t('add') : $t('update') }}</a>
                                                </div>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import AdminMenu from '../components/AdminMenu';
import { collection, doc, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import Swal from 'sweetalert2';

export default {
    components: {
        AdminMenu
    },
    data() {
        return {
            formData: {
                id: 0,
                name: '',
                url: '',
                weeklyPrice: 0,
                status: true
            },
            baseUrl: process.env.VUE_APP_BASE_URL
        };
    },
    async mounted() {
        const id = this.$route.params.id;
        if(id!='0'){
            const locationData = await this.getLocation(id);

            if (locationData) {
                this.formData = { ...locationData };
                this.formData.id = id;
            }
        }
    },  
    methods:{
        async getLocation(id) {
            this.$setLoading(true);
            const documentRef = doc(getFirestore(), 'locations', id);
            const documentSnapshot = await getDoc(documentRef);
            this.$setLoading(false);
            if (documentSnapshot.exists()) {
                return {
                id: id,
                ...documentSnapshot.data()
                };
            }
            return null;
        },
        async submitForm() {
            const id = this.$route.params.id;

            if(this.formData.name=="" || this.formData.url=="" || this.formData.weeklyPrice==0 || this.formData.weeklyPrice==""){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('fillInAllTheFields'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                try {

                    this.$setLoading(true);
                    this.formData.url = this.makeUrlFriendly(this.formData.url);
                    if (id == '0') {
                        const documentRef = collection(getFirestore(), 'locations');
                        await addDoc(documentRef, { ...this.formData, createdDate: serverTimestamp() });
                        this.formData= {
                            id: 0,
                            name: '',
                            url: '',
                            weeklyPrice: 0,
                            status: true
                        };
                    } else {
                        const documentRef = doc(getFirestore(), 'locations', id);
                        await updateDoc(documentRef, { ...this.formData });
                    }
                    this.$setLoading(false);

                    Swal.fire({
                        title: this.$t('success'),
                        text: this.$t('saveSuccessful'),
                        icon: 'success',
                        confirmButtonText: this.$t('ok'),
                        confirmButtonColor: '#0f7438',
                    });
                    
                } catch (error) {
                    this.$setLoading(false);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                }
            }


        },
        makeUrlFriendly(text) {
            text = text.toLowerCase();
            text = text.split(" ").join("-");
            const urlFriendlyText = text.replace(/\s+/g, '-');
            const encodedUrl = encodeURIComponent(urlFriendlyText);
            return encodedUrl;
        }
    }
}
</script>