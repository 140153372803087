<template>


        <!-- Start breadcrumb Area -->
    <div class="rbt-breadcrumb-default ptb--40 ptb_md--50 ptb_sm--30 bg-gradient-1">
        <div style="text-align: center;margin-bottom: 30px;">
            <a href="#/employer"><img src="assets/images/logo/logo.png" :alt="$t('projectName')" style="max-width: 400px;"></a>
        </div>
    </div>
    <!-- End Breadcrumb Area -->

    <div class="rbt-elements-area bg-color-white rbt-section-gap" style="padding-top: 10px;">
        <div class="container">
            <div class="row gy-5 row--30">

                <div class="col-lg-6" style="float:none;margin:auto;">
                    <div class="rbt-contact-form contact-form-style-1 max-width-auto">
                        <h3 class="title" style="margin-bottom: 15px;">{{$t("createNewAccount")}}</h3>
                        <form class="max-width-auto" @submit.prevent="register"> 
                            <div class="form-group">
                                <input name="companyName" type="text" v-model="form.companyName">
                                <label>{{$t("companyName")}} *</label>
                                <span class="focus-border"></span>
                            </div>
                            <div class="form-group">
                                <input name="authorizedPersonName" type="text" v-model="form.authorizedPersonName">
                                <label>{{$t("authorizedPersonName")}} *</label>
                                <span class="focus-border"></span>
                            </div>
                            <div class="form-group">
                                <input name="companyEmailAddress" type="text" v-model="form.companyEmailAddress">
                                <label>{{$t("companyEmailAddress")}} *</label>
                                <span class="focus-border"></span>
                            </div>
                            <div class="form-group">
                                <input name="password" type="password" v-model="form.password">
                                <label>{{$t("password")}} *</label>
                                <span class="focus-border"></span>
                            </div>
                            <div class="form-group">
                                <input name="confirmPassword" type="password" v-model="form.confirmPassword">
                                <label>{{$t("confirmPassword")}} *</label>
                                <span class="focus-border"></span>
                            </div>

                            <div class="form-submit-group">
                                <button type="submit" class="rbt-btn btn-md btn-gradient hover-icon-reverse w-100" @click="register">
                                    <span class="icon-reverse-wrapper">
                                        <span class="btn-text">{{$t("register")}} </span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    <span class="btn-icon"><i class="feather-arrow-right"></i></span>
                                    </span>
                                </button>
                            </div>

                            <div class="row mt--30">
                                <div class="col-12">
                                    <div class="rbt-lost-password text-center">
                                        <a class="rbt-btn-link" href="#/login">{{$t("returnToLogin")}}</a>
                                    </div>
                                </div>
                            </div>

    
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>


</template>
<script>

//import { collection, doc, getDoc, addDoc, setDoc, updateDoc, serverTimestamp, query, where, getDocs  } from "firebase/firestore";
//import { getFirestore } from "firebase/firestore";
//import { getStorage, uploadBytes, ref as storageRef, getDownloadURL, deleteObject  } from 'firebase/storage';
import { getFunctions, httpsCallable } from "firebase/functions";
//import db from '../firebase/init';
import Swal from 'sweetalert2';

export default {
    components: {
    
    },
    data() {
        return {
            form:{
                companyName: "",
                authorizedPersonName: "",
                companyEmailAddress: "",
                password: "",
                confirmPassword: ""
            }
        };
    },
    async mounted() {

    },

    methods:{
        handleFileChange(event) {
            this.upload.selectedImage = event.target.files[0];
        },
        generateRandomNumber() {
            const min = 100000;
            const max = 999999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async register(){
            const email = this.form.companyEmailAddress;

            var mailPattern = new RegExp("^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$");

            if(this.form.companyName == "" || this.form.authorizedPersonName == "" || this.form.companyEmailAddress == "" || this.form.password == "" || this.form.confirmPassword == ""){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('fillInAllTheFields'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if((!mailPattern.test(email))){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('invalidEmail'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            } else if(this.form.password != this.form.confirmPassword){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('passwordDoNotMatch'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else if(this.form.password.length < 6){
                Swal.fire({
                    title: this.$t('warning'),
                    text: this.$t('passwordMinCharacter'),
                    icon: 'warning',
                    confirmButtonText: this.$t('ok')
                });
            }else{
                this.$setLoading(true);
                const functions = getFunctions();
                httpsCallable(functions,"createCustomerUser")({email: this.form.companyEmailAddress, password: this.form.password, name: this.form.authorizedPersonName, photoUrl: '', companyName: this.form.companyName}).then(async result => {
                    console.log(result);
                    this.$setLoading(false);
                    if(result.data.success==true){
                    
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('userCreateSuccessMessage'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                    }else{
                        
                        Swal.fire({
                            title: this.$t('error'),
                            text: result.data.message,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                    
                })
                .catch(error => {
                    this.$setLoading(false);
                    Swal.fire({
                        title: this.$t('error'),
                        text: error,
                        icon: 'error',
                        confirmButtonText: this.$t('ok')
                    });
                });
            }
        }
    }
}
</script>