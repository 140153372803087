
<template>
    <div class="rbt-card variation-01 rbt-hover" v-if="jobItem.adType=='image'">
        <div class="rbt-card-body text-center">
            <a href="javascript:;" class="rbt-badge-2">
                <div class="image">
                    <img :src="jobItem.companyPhotoUrl == '' ? '/assets/images/no_user_image.png' : jobItem.companyPhotoUrl">
                </div> Job advertisement from <strong>{{ jobItem.companyName }}</strong>
            </a>
            <h4 class="rbt-card-title rbt-badge-6 bg-primary-opacity mt-5" style="text-transform: none;line-height: 30px;">{{ jobItem.jobName }}</h4>
            
            <img :src="jobItem.imageUrl" style="width: 100%;height: 280px;">

            <div class="text-end" style="font-size: 80px;" v-if="isTv">
                <qrcode-vue :value="baseUrl+'/#/view/'+jobItem.id" :size="100"></qrcode-vue>
            </div>
        </div>
    </div>

    <div class="rbt-card variation-01 rbt-hover" v-if="jobItem.adType=='design'">
        <div class="rbt-card-body text-center">
            <a href="javascript:;" class="rbt-badge-2">
                <div class="image">
                    <img :src="jobItem.companyPhotoUrl == '' ? '/assets/images/no_user_image.png' : jobItem.companyPhotoUrl">
                </div> Job advertisement from <strong>{{ jobItem.companyName }}</strong>
            </a>
            <h4 class="rbt-card-title rbt-badge-6 bg-primary-opacity mt-5" style="text-transform: none;line-height: 30px;">{{ jobItem.jobName }}</h4>
            <ul class="rbt-meta mt-5 mb-5">
                <li><i class="fa fa-clock"></i> {{ jobItem.workingType }}</li>
            </ul>
            <ul class="rbt-meta mt-5 mb-5">
                <li><i class="fa fa-tasks"></i> {{ jobItem.responsibilities }}</li>
            </ul>
            <ul class="rbt-meta mt-5 mb-5">
                <li><i class="fa fa-star"></i> {{ jobItem.benefits }}</li>
            </ul>

            <div class="rbt-card-bottom">
                <div class="rbt-price rbt-badge-6 bg-secondary-opacity mt-5" style="text-transform: none;">
                    <span class="current-price"><i class="fa fa-wallet"></i> ${{ jobItem.salaryPaid }} <span style="font-size:18px;">({{ jobItem.salaryPeriod }})</span></span>
                </div>
                <a class="rbt-btn-link mt-5" href="javascript:;" v-if="isTv">for detail<i class="feather-arrow-down"></i><br><qrcode-vue :value="baseUrl+'/#/view/'+jobItem.id" :size="100"></qrcode-vue></a>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import QrcodeVue from 'qrcode.vue';
  export default {
    props: {
        jobItem: Object,
        isTv: Boolean
    },
    components: {
        QrcodeVue
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_BASE_URL
        }
    },
  }
  </script>