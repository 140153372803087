<template>

    <!-- Start Card Style -->
    <div class="rbt-dashboard-area rbt-section-gapBottom mt--20">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">

                    <div class="row g-5">
                        <div class="col-lg-3">
                            <!-- Start Dashboard Sidebar  -->
                            <AdminMenu :activeMenu="'companies'" />
                            <!-- End Dashboard Sidebar  -->
                        </div>

                        <div class="col-lg-9">
                            <div class="rbt-dashboard-content bg-color-white rbt-shadow-box mb--60">
                                <div class="content">
                                    <div class="section-title">
                                        <h4 class="rbt-title-style-3">{{ $t("companies") }}</h4>
                                    </div>
                                    <div class="row g-5">
                                        
                                        <div class="rbt-dashboard-table table-responsive mobile-table-750 mt--30">
                                            <table class="rbt-table table table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th>{{ $t("companyName") }}</th>
                                                        <th>{{ $t("authorizedPersonName") }}</th>
                                                        <th>{{ $t("createdDate") }}</th>
                                                        <th>{{ $t("status") }}</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in items" :key="item[id]">
                                                        <th>
                                                            <p class="b3">{{ item["companyName"] }}</p>
                                                        </th>
                                                        <th>
                                                            <p class="b3">{{ item["name"] }}</p>
                                                        </th>
                                                        <td>
                                                            <p class="b3">{{ item["createdDate"] }}</p>
                                                        </td>
                                                        <td>
                                                            <p class="b3" v-if="item['status']">
                                                                <span class="rbt-badge-5 bg-color-info-opacity color-success">{{ $t("active") }}</span>
                                                            </p>
                                                            <p class="b3" v-if="!item['status']">
                                                                <span class="rbt-badge-5 bg-color-danger-opacity color-danger">{{ $t("passive") }}</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div class="rbt-button-group justify-content-end">
                                                                <a class="rbt-btn btn-xs bg-color-primary-opacity radius-round color-primary" href="javascript:;" title="Edit" @click="editData(item.id)"><i class="feather-edit-2 pl--0"></i> {{ $t("edit") }}</a>
                                                                <a class="rbt-btn btn-xs bg-color-danger-opacity radius-round color-danger" href="javascript:;" title="Delete" @click="deleteData(item.id)"><i class="feather-trash-2 pl--0"></i> {{ $t("delete") }}</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Card Style -->

</template>
<script>
import AdminMenu from '../components/AdminMenu';
import { query, collection, getDocs, doc, deleteDoc, where } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import db from '../firebase/init';
import Swal from 'sweetalert2';

export default {
    components: {
        AdminMenu
    },
    data() {
        return {
            items: []
        };
    },
    mounted() {
        this.getCompanies();
    },  
    methods: {
        async getCompanies() {

            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'users'), where('role','==','employer')));
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    companyName: doc.data().companyName,
                    name: doc.data().name,
                    createdDate: this.$formatDate(doc.data().createdDate),
                    status: doc.data().status,
                };
            });
            
        },
        editData(_id){
            this.$router.push({ name: 'admin-company-add', params: { id: _id } });
        },
        deleteData(_id) {
            Swal.fire({
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureDelete'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#0f7438',
                cancelButtonColor: '#6c757d',
                confirmButtonText: this.$t('delete'),
                cancelButtonText: this.$t('cancel')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const documentRef = doc(getFirestore(), 'users', _id);
                    try {
                        this.$setLoading(true);
                        await deleteDoc(documentRef);
                        this.$setLoading(false);                       
                        Swal.fire({
                            title: this.$t('success'),
                            text: this.$t('deleteSuccessful'),
                            icon: 'success',
                            confirmButtonText: this.$t('ok'),
                            confirmButtonColor: '#0f7438',
                        });
                        this.items = [];
                        
                        await this.getCompanies();
                    } catch (error) {
                        this.$setLoading(false);
                        Swal.fire({
                            title: this.$t('error'),
                            text: error,
                            icon: 'error',
                            confirmButtonText: this.$t('ok')
                        });
                    }
                }
            });
        },
    },
}
</script>