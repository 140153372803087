
<template>
    <div class="rbt-default-sidebar sticky-top rbt-shadow-box rbt-gradient-border">
        <div class="inner">
            <div class="content-item-content">

                <div class="rbt-default-sidebar-wrapper">
                    <div class="section-title mb--20">
                        <h6 class="rbt-title-style-2">{{ $t("welcome") }},<br>{{ userName }}</h6>
                    </div>
                    <nav class="mainmenu-nav">
                        <ul class="dashboard-mainmenu rbt-default-sidebar-list">
                            <li><a href="#/admin-dashboard" :class="activeMenu=='dashboard'?'active':''"><i class="feather-home"></i><span>{{ $t("dashboard") }}</span></a></li>
                            <li><a href="#/admin-locations" :class="activeMenu=='locations'?'active':''"><i class="feather-map-pin"></i><span>{{ $t("locations") }}</span></a></li>
                            <li><a href="#/admin-companies" :class="activeMenu=='companies'?'active':''"><i class="feather-layers"></i><span>{{ $t("companies") }}</span></a></li>
                            <li><a href="#/admin-posts" :class="activeMenu=='posts'?'active':''"><i class="feather-list"></i><span>{{ $t("jobPostings") }}</span></a></li>
                            <li><a href="#/admin-live" :class="activeMenu=='live'?'active':''"><i class="feather-tv"></i><span>{{ $t("liveTv") }}</span></a></li>
                            <li><a href="#/admin-profile" :class="activeMenu=='profile'?'active':''"><i class="feather-user"></i><span>{{ $t("myProfile") }}</span></a></li>
                            <li><a href="#/logout"><i class="feather-log-out"></i><span>{{ $t("logout") }}</span></a></li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      activeMenu: String
    },
    data(){
        return{
            userName: localStorage.getItem("name")
        }
    }
  }
  </script>