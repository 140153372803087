<template>
<div class="row" style="text-align: center;">
    <div class="col-12">
        <img src="assets\images\logo\logo.png" style="width:250px;">
    </div>
    <div class="">
        <div class="container1" :style="'grid-template-rows:'+rowsStyle+';grid-template-columns:'+columsStyle+';'">
            <a class="box" v-for="item in items" :key="item.id" :href="'#/view/'+item.id" target="_blank">
                <JobItemLive :jobItem="item" :isTv="true"></JobItemLive>
            </a>
        </div>
    </div>
</div>
</template>
<script>

import JobItemLive from '../components/JobItemLive';
import { getDocs, query, where, collection, orderBy, limit } from "firebase/firestore";
import db from '../firebase/init';
export default {
    components: {
        JobItemLive
    },
    data() {
        return {
            items: [],
            columsStyle: "",
            rowsStyle: ""
        };
    },
    async mounted() {
        const url = this.$route.params.id;
        await this.getJobs(url);

        this.setDesignColumn();

        this.interval = setInterval(this.reloadPage, 60000*5);
    },  
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods:{
        async getJobs(locationUrl){
            this.$setLoading(true);
            const querySnap = await getDocs(query(collection(db, 'jobs'), where('locationUrl','==',locationUrl), where('status','==','active'), orderBy('orderNumber', 'asc'), limit(20)));
            //const querySnap = await getDocs(query(collection(db, 'jobs')));
            this.$setLoading(false);
            this.items = querySnap.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                };
            });
            
        },
        setDesignColumn(){
            const num = this.items.length;
            let colNumber = 1;
            if(num>1 && num<=6) colNumber = 2;
            if(num>6 && num<=12) colNumber = 3;
            if(num>12) colNumber = 4;

            const rows = Math.ceil(num / colNumber);
            this.rowsStyle = 'repeat('+num+'}, 1fr)';
            this.columsStyle = 'repeat('+colNumber+', 1fr)';
        },
        reloadPage() {
            location.reload();
        }
    }
}
</script>

<style scoped>
        body, html {
            height: 100%;
            margin: 0;
        }
        .container1 {
            display: grid;
            height: 100%;
            width: 100%;
            grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
            grid-template-columns: 1fr;
        }

        .box {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #b7b7b7;
            background-color: #f0f0f0;
        }
</style>