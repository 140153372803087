<template>
<div class="container">
    
    <p style="margin-top: 20px;">
        <a class="rbt-btn-link" href="#/"><i class="feather-arrow-left"></i> Go Home</a>
    </p>
    <h1>Security and Privacy Policy</h1>

    <p>
        <b>Introduction:</b><br>
        At {{ baseUrl }}, operated by YASAM LLC, we are committed to protecting the security and privacy of our users and advertisers. This Security and Privacy Policy outlines how we collect, use, and safeguard personal information, as well as the measures we take to ensure the security of our platform. By accessing or using our website, you agree to comply with this policy. If you do not agree with any part of this policy, you may not access or use our services.
    </p>
    
    <p>
        <b>Collection of Personal Information:</b><br>
        We collect personal information from users and advertisers when they register on our website, submit advertisements, or communicate with us via email or other channels. This information may include name, email address, contact information, and payment details. We may also collect non-personal information such as IP addresses, browser type, and device information for analytics and security purposes.
    </p>

    <p>
        <b>Use of Personal Information:</b><br>
        We use personal information to provide and improve our services, process transactions, communicate with users and advertisers, and comply with legal obligations. Personal information may also be used for marketing purposes, such as sending promotional emails or newsletters. Users and advertisers have the option to opt out of marketing communications at any time.
    </p>

    <p>
        <b>Protection of Personal Information:</b><br>
        We implement a variety of security measures to protect the personal information of our users and advertisers. This includes encryption of sensitive data, regular security audits, and access controls to restrict unauthorized access to personal information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
    </p>

    <p>
        <b>Disclosure of Personal Information:</b><br>
        We do not sell, trade, or otherwise transfer personal information to third parties without user or advertiser consent, except as required by law or to fulfill service obligations. We may share personal information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing users and advertisers, provided that they agree to keep the information confidential.
    </p>

    <p>
        <b>Data Retention:</b><br>
        We retain personal information for as long as necessary to fulfill the purposes outlined in this Security and Privacy Policy, unless a longer retention period is required or permitted by law. When personal information is no longer needed, we will securely delete or anonymize it to prevent unauthorized access or use.
    </p>

    <p>
        <b>Third-Party Links:</b><br>
        Our website may contain links to third-party websites or resources. These links are provided for convenience only and do not imply endorsement or approval of the linked sites. We are not responsible for the privacy practices or content of third-party websites, and users access them at their own risk.
    </p>

    <p>
        <b>Children's Privacy:</b><br>
        Our services are not directed to individuals under the age of 18, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided personal information to us, please contact us immediately to request the removal of their information from our systems.
    </p>

    <p>
        <b>Changes to Security and Privacy Policy:</b><br>
        We reserve the right to modify or update this Security and Privacy Policy at any time without prior notice. Users and advertisers are encouraged to review this policy periodically for any changes. Continued use of our services after the posting of changes constitutes acceptance of those changes.
    </p>

    <p>
        <b>Contact Information:</b><br>
        If you have any questions or concerns about this Security and Privacy Policy, please contact us at peakcareer1@gmail.com.
    </p>

    <p>
        <b>Effective Date:</b><br>
        This Security and Privacy Policy is effective as of April 1, 2024, and supersedes all prior versions.
    </p>

    <p>
        <br>
    </p>

</div>
</template>
<script>


export default {
    components: {
    
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE_URL
        };
    },
    async mounted() {

    },  
    methods:{

    }
}
</script>